<template>
  <div class="header" :class="{ light: theme == 'light' }">
    <div class="header-container">
      <div class="logo">
        <a href="/">
          <img v-if="theme == 'light'" src="../assets/logo-light.svg" />
          <img v-else src="../assets/logo.svg" />
        </a>

      </div>
      <div class="header-links p3-font-size">

        <div class="link audio-controller">
          <a>

            <button :class="{ active: isAudioOn }" @click="muteAudio">
              <div id="audioOff" ref="audioOff">

                <img src="/assets/audio_off.svg" />
              </div>
              <div id="audioOn" ref="audioOn">
                <img src="/assets/audio-on.svg" />
              </div>
            </button>

          </a>
        </div>

        <div class="header-menu-icon" :class="{ active: showMenu }" @click="showMenu = !showMenu">
          <div class="menu-bar menu-bar1">
            <svg width="38" height="4" viewBox="0 0 38 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0427 0.417994C34.7769 0.4985 30.0471 0.74359 29.6184 0.705516C28.2963 0.588126 21.9973 0.705516 21.9973 0.705516L13.228 0.705516C8.46118 0.586146 6.16693 0.21891 6.16693 0.21891L5.16382 0.21891L4.16071 0.21891L3.15761 0.21891L2.27856 0.21891C2.19825 0.21891 2.1207 0.24548 2.06089 0.293467L1.85373 0.459238C1.8288 0.479464 1.80775 0.502862 1.79058 0.528638L0.0469125 3.1552C-0.0721756 3.33406 0.0469125 3.56487 0.275119 3.59858L2.99476 3.99636C3.01525 3.99913 3.0363 4.00032 3.05679 3.99993L21.31 3.50896L21.3299 3.52879L28.1622 3.52879C28.1766 3.52879 28.1911 3.528 28.2049 3.52601L37.0684 2.46951C37.6212 2.43858 38 2.31485 38 2.03407C38 1.78819 37.2822 0.897464 36.5693 0C35.8537 0.193532 35.164 0.381508 35.0427 0.417994Z"
                fill="#745246" />
            </svg>
          </div>
          <div class="menu-bar menu-bar2">
            <svg width="38" height="4" viewBox="0 0 38 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0427 0.417994C34.7769 0.4985 30.0471 0.74359 29.6184 0.705516C28.2963 0.588126 21.9973 0.705516 21.9973 0.705516L13.228 0.705516C8.46118 0.586146 6.16693 0.21891 6.16693 0.21891L5.16382 0.21891L4.16071 0.21891L3.15761 0.21891L2.27856 0.21891C2.19825 0.21891 2.1207 0.24548 2.06089 0.293467L1.85373 0.459238C1.8288 0.479464 1.80775 0.502862 1.79058 0.528638L0.0469125 3.1552C-0.0721756 3.33406 0.0469125 3.56487 0.275119 3.59858L2.99476 3.99636C3.01525 3.99913 3.0363 4.00032 3.05679 3.99993L21.31 3.50896L21.3299 3.52879L28.1622 3.52879C28.1766 3.52879 28.1911 3.528 28.2049 3.52601L37.0684 2.46951C37.6212 2.43858 38 2.31485 38 2.03407C38 1.78819 37.2822 0.897464 36.5693 0C35.8537 0.193532 35.164 0.381508 35.0427 0.417994Z"
                fill="#745246" />
            </svg>
          </div>
          <div class="menu-bar menu-bar2-1">
            <svg width="38" height="4" viewBox="0 0 38 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0427 0.417994C34.7769 0.4985 30.0471 0.74359 29.6184 0.705516C28.2963 0.588126 21.9973 0.705516 21.9973 0.705516L13.228 0.705516C8.46118 0.586146 6.16693 0.21891 6.16693 0.21891L5.16382 0.21891L4.16071 0.21891L3.15761 0.21891L2.27856 0.21891C2.19825 0.21891 2.1207 0.24548 2.06089 0.293467L1.85373 0.459238C1.8288 0.479464 1.80775 0.502862 1.79058 0.528638L0.0469125 3.1552C-0.0721756 3.33406 0.0469125 3.56487 0.275119 3.59858L2.99476 3.99636C3.01525 3.99913 3.0363 4.00032 3.05679 3.99993L21.31 3.50896L21.3299 3.52879L28.1622 3.52879C28.1766 3.52879 28.1911 3.528 28.2049 3.52601L37.0684 2.46951C37.6212 2.43858 38 2.31485 38 2.03407C38 1.78819 37.2822 0.897464 36.5693 0C35.8537 0.193532 35.164 0.381508 35.0427 0.417994Z"
                fill="#745246" />
            </svg>
          </div>
          <div class="menu-bar menu-bar2-2">
            <svg width="38" height="4" viewBox="0 0 38 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0427 0.417994C34.7769 0.4985 30.0471 0.74359 29.6184 0.705516C28.2963 0.588126 21.9973 0.705516 21.9973 0.705516L13.228 0.705516C8.46118 0.586146 6.16693 0.21891 6.16693 0.21891L5.16382 0.21891L4.16071 0.21891L3.15761 0.21891L2.27856 0.21891C2.19825 0.21891 2.1207 0.24548 2.06089 0.293467L1.85373 0.459238C1.8288 0.479464 1.80775 0.502862 1.79058 0.528638L0.0469125 3.1552C-0.0721756 3.33406 0.0469125 3.56487 0.275119 3.59858L2.99476 3.99636C3.01525 3.99913 3.0363 4.00032 3.05679 3.99993L21.31 3.50896L21.3299 3.52879L28.1622 3.52879C28.1766 3.52879 28.1911 3.528 28.2049 3.52601L37.0684 2.46951C37.6212 2.43858 38 2.31485 38 2.03407C38 1.78819 37.2822 0.897464 36.5693 0C35.8537 0.193532 35.164 0.381508 35.0427 0.417994Z"
                fill="#745246" />
            </svg>
          </div>
          <div class="menu-bar menu-bar3">
            <svg width="38" height="4" viewBox="0 0 38 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0427 0.417994C34.7769 0.4985 30.0471 0.74359 29.6184 0.705516C28.2963 0.588126 21.9973 0.705516 21.9973 0.705516L13.228 0.705516C8.46118 0.586146 6.16693 0.21891 6.16693 0.21891L5.16382 0.21891L4.16071 0.21891L3.15761 0.21891L2.27856 0.21891C2.19825 0.21891 2.1207 0.24548 2.06089 0.293467L1.85373 0.459238C1.8288 0.479464 1.80775 0.502862 1.79058 0.528638L0.0469125 3.1552C-0.0721756 3.33406 0.0469125 3.56487 0.275119 3.59858L2.99476 3.99636C3.01525 3.99913 3.0363 4.00032 3.05679 3.99993L21.31 3.50896L21.3299 3.52879L28.1622 3.52879C28.1766 3.52879 28.1911 3.528 28.2049 3.52601L37.0684 2.46951C37.6212 2.43858 38 2.31485 38 2.03407C38 1.78819 37.2822 0.897464 36.5693 0C35.8537 0.193532 35.164 0.381508 35.0427 0.417994Z"
                fill="#745246" />
            </svg>
          </div>
          
        </div>

        <div class="menu-container" :class="{ active: showMenu }">
          <div class="menu">
            <ul>
              <li @click="enableAudio">
                <a href="chapter1" @click="refreshPage()">
                  <!--<a href="/chapter1"   >-->
                  <span class="p2-font-size story-number">01</span>
                  <span class="menu-item">
                    <span class="h4-font-size story-title">جـذور الـتأسـيـس</span>
                    <span class="p1-font-size story-year">420 م</span>
                  </span>
                  <span class="menu-item menu-item-hover">
                    <span class="h4-font-size story-title">جـذور الـتأسـيـس</span>
                    <span class="p1-font-size story-year">420 م</span>
                  </span>

                  <span class="menu-arrow"><img src="../assets/menu-arrow.svg" /></span>
                  <!-- </a>-->
                </a>
              </li>
              <li @click="enableAudio">
                <a href="/foundingstory/chapter2" @click="refreshPage()">
                  <!-- <a href="/chapter2"   @click="muteAudio">-->
                  <span class="p2-font-size story-number">02</span>
                  <span class="menu-item">
                    <span class="h4-font-size story-title">مـانـع بـن ربـيـعـة المـريـدي</span>
                    <span class="p1-font-size story-year">
                      1446 م - 850 هـ
                    </span>
                  </span>
                  <span class="menu-item menu-item-hover">
                    <span class="h4-font-size story-title">مـانـع بـن ربـيـعـة المـريـدي</span>
                    <span class="p1-font-size story-year">1446 م - 850 هـ</span>
                  </span>

                  <span class="menu-arrow"><img src="../assets/menu-arrow.svg" /></span>
                  <!--</a>-->
                </a>
              </li>
              <li @click="enableAudio">
                <a href="chapter3" @click="refreshPage()">
                  <span class="p2-font-size story-number">03</span>
                  <span class="menu-item">
                    <span class="h4-font-size story-title">المـؤسـس الإمـام محـمـد بـن سـعـود</span>
                    <span class="p1-font-size story-year">
                      1727 م - 1139 هـ
                    </span>
                  </span>
                  <span class="menu-item menu-item-hover">
                    <span class="h4-font-size story-title">المـؤسـس الإمـام محـمـد بـن سـعـود</span>
                    <span class="p1-font-size story-year">1727 م - 1139 هـ</span>
                  </span>

                  <span class="menu-arrow"><img src="../assets/menu-arrow.svg" /></span>
                </a>
              </li>
              <li @click="enableAudio">
                <a href="chapter4" @click="refreshPage()">
                  <span class="p2-font-size story-number">04</span>
                  <span class="menu-item">
                    <span class="h4-font-size story-title">الـثـقـافـة والازدهـار</span>
                    <span class="p1-font-size story-year">1728 م - 1140 هـ</span>
                  </span>
                  <span class="menu-item menu-item-hover">
                    <span class="h4-font-size story-title">الـثـقـافـة والازدهـار</span>
                    <span class="p1-font-size story-year">1728 م - 1140 هـ</span>
                  </span>

                  <span class="menu-arrow"><img src="../assets/menu-arrow.svg" /></span>

                </a>
              </li>
              <li @click="enableAudio">
                <a href="/">
                  <span class="p2-font-size story-number"></span>
                  <span class="menu-item">
                    <span class="h4-font-size story-title">العودة للموقع</span>
                    <span class="p1-font-size story-year"></span>
                  </span>
                  <span class="menu-item menu-item-hover">
                  <span class="h4-font-size story-title">العودة للموقع</span>
                    <span class="p1-font-size story-year"></span>
                  </span>

                  <span class="menu-arrow"><img src="../assets/menu-arrow.svg" /></span>

                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import emitter from 'tiny-emitter/instance';
export default {
  name: 'HeaderComponent',
  components: {
    LottieAnimation
  },
  props: {
    theme: String
  },

  data: () => {
    return {
      showMenu: false,
      isAudioOn: false
    };
  },
  methods: {
    muteAudio() {
      this.isAudioOn = !this.isAudioOn
      emitter.emit('updateAudio', this.isAudioOn)
    },
    enableAudio() {
      this.isAudioOn = true
      emitter.emit('updateAudio', true)
    },
    refreshPage() {
      location.reload(true)
    }
  },
  mounted() {
    emitter.on('updateAudio', (isAudioOn) => {
      this.isAudioOn = isAudioOn;
    })
  }
}
</script>



<style lang="scss">
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;



  &.light {
    color: var(--beige);

    svg * {
      fill: var(--beige) !important;
      stroke: var(--beige) !important;


    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .logo img {
    display: flex;
    align-items: center;
    width: var(--logoWidth);
  }

  .header-links {
    display: flex;
    align-items: center;

    .link {
      padding-inline-end: 1.5vw;

      @media all and (max-width:600px) {
        padding-inline-end: 3vw;
      }
    }
  }

  .audio-controller a {
    display: flex;
    align-items: center;
  }

  .header-menu-icon {
    display: flex;
    flex-flow: row wrap;
    width: 45px;
    height: 25px;
    justify-content: flex-end;
    align-content: space-between;
    cursor: pointer;
    position: relative;
    z-index: 11111;

    svg * {
      fill: var(--brown);
      transition: all 0.2s ease 1.3s;
    }

    &.active {



      svg * {
        fill: var(--beige);
        transition: all 0.2s ease 0s;
      }

      .menu-bar {
        &.menu-bar1 {
          opacity: 0;
          transform: translateY(11px);
          transition: opacity 0s ease 0.5s, transform 0.5s ease 0s;
        }

        &.menu-bar3 {
          opacity: 0;
          transform: translateY(-11px);
          transition: opacity 0s ease 0.5s, transform 0.5s ease 0s;
        }

        &.menu-bar2-1 {
          transform: rotate(45deg);
          transition: all 0.5s ease 0.5s;
        }

        &.menu-bar2-2 {
          transform: rotate(-45deg);
          transition: all 0.5s ease 0.5s;
        }

        &.menu-bar2 {
          opacity: 0;
          transition: all 0s ease 0.5s;
        }
      }
    }

    .menu-bar {
      width: 100%;
      height: 4px;
      opacity: 1;
      display: block;
      transform: translateY(0px);
      transition: opacity 0s ease 0s, transform 0.5s ease 0.5s;

      svg {
        width: 100%;
        display: flex;
        align-items: flex-end;
      }

      &.menu-bar2 {
        opacity: 1;
        transition: all 0s ease 0.5s;
      }

      &.menu-bar1,
      &.menu-bar3 {
        opacity: 1;
        transition: transform 0.5s ease 0.5s, opacity 0s ease 0.5s;
      }

      &.menu-bar2-1,
      &.menu-bar2-2 {
        position: absolute;
        top: 10px;
        left: 0;
        height: auto;
        transform: rotate(0deg);
        transition: transform 0.5s ease 0s, opacity 0s ease 0.5s;
      }
    }

    @media all and (max-width:600px) {
      width: 30px;
      height: 20px;


      .menu-bar.menu-bar2-1,
      .menu-bar.menu-bar2-2 {
        top: 8px;
      }

      &.active {

        .menu-bar {
          &.menu-bar1 {
            opacity: 0;
            transform: translateY(8px);
            transition: opacity 0s ease 0.5s, transform 0.5s ease 0s;
          }

          &.menu-bar3 {
            opacity: 0;
            transform: translateY(-8px);
            transition: opacity 0s ease 0.5s, transform 0.5s ease 0s;
          }


        }
      }
    }
  }

  .menu-container {
    --headerPadding: 1.1vw 0;

    position: fixed;
    left: 0;
    width: 70vw;
    height: 100vh;
    top: 0;
    background-color: var(--brown);
    color: var(--pink);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3vw;
    left: -100%;
    opacity: 0;
    flex-flow: row wrap;
    transition: all 0.5s ease-out 0.8s, opacity 0.5s ease 1.2s;

    &.active {
      left: 0;
      opacity: 1;
      transition: all 0.5s ease-out 0s, opacity 0.5s ease 0s;

      .menu {
        opacity: 1;
        transition: all 1s ease 1s;
      }

      .menu ul {
        li {
          &:after {
            width: 100%;
            transition: all 0.5s cubic-bezier(0.62, 0.05, 0.01, 0.99) 1s;
          }
        }
      }

      .header-bottom {
        opacity: 1;
        transition: all 1s ease 1s;
      }
    }

    .menu {
      width: 100%;
      opacity: 0;
      transition: all 1s ease 0s;
    }

    .menu ul {

      li {
        list-style: none;

        &:after {
          content: "";
          height: 3px;
          background-color: var(--pink);
          width: 0%;
          display: block;
          margin: var(--headerPadding);
          transition: all 0.5s cubic-bezier(0.62, 0.05, 0.01, 0.99) 0s;
        }

        .story-title,
        .story-year {
          padding-inline-start: 2vw;
        }

        .story-year {
          line-height: 0.8;
        }

        a {
          position: relative;
          display: block;
          overflow: hidden;
          cursor: pointer;
          text-decoration: none;
          color: var(--pink);

          .story-number {
            padding-bottom: 10px;
          }

          span {
            display: block;
            transition: all 0.5s ease;
          }

          .menu-item.menu-item-hover {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateY(calc(150% + 10px));
            color: var(--beige);
          }

          &:hover {
            .menu-item {
              transform: translateY(calc(-150% - 10px));
            }

            .menu-item-hover {
              transform: translateY(0);
            }

            .menu-arrow {
              img {
                transform: translate(0);
              }
            }
          }

          .menu-arrow {
            position: absolute;
            left: 2vw;
            display: flex !important;
            align-items: center;
            margin-top: auto;
            margin-bottom: auto;
            top: 50%;
            overflow: hidden;

            img {
              transform: translateY(100%) translateX(100%);
              transition: all 0.5s ease;
            }
          }
        }
      }
    }

    .header-bottom {
      opacity: 0;
      transition: all 1s ease 0.5s;

      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .social-media-container ul {
        display: flex;
        align-items: center;

        li {
          list-style: none;
          padding-inline-end: 2vw;

          img {
            display: flex;
            min-width: 45vw;
          }
        }
      }
    }

    .header-logos {
      display: flex;
      align-items: flex-end;
    }

    .antame-logo {
      margin-inline-start: 1vw;

      img {
        display: flex;
        max-width: 7vw;
      }

      @media all and (max-width:600px) {
        margin-inline-start: 10px;
      }
    }

    .darah-logo img {
      max-width: 15vw;
      display: flex;
    }

    @media all and (max-width:600px) {
      --headerPadding: 4.1vw 0;
      width: 100vw;

      .darah-logo img {
        max-width: 23vw;
      }

      .menu ul li a .menu-arrow img {
        width: 20px;
      }
    }
  }

  #audioOff {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  button {
    border: 0;
    cursor: pointer;
    position: relative;
    background-color: transparent;

    #audioOff {
      opacity: 1;
      transition: all 0.2s ease 0s;
    }

    &.active {
      #audioOff {
        opacity: 0;

      }
    }

    svg,
    svg * {
      stroke: var(--brown) !important;
    }
  }
}
</style>



