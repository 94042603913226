<template>
<metainfo>
    <template v-slot:title="{ content }">{{ content}}</template>
  </metainfo>
<Header></Header>
<Chapter1Scene />

<div class="screen-orientation" id="screenOrientation">
<div class="screen-orientation-container">
<div class="screen-icon"><img src="../assets/rotate-device.svg" /></div>
<div class="message">الرجاء إعادة الشاشة للوضع العامودي</div>
</div>
</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import Header from '../components/Header.vue'
import Chapter1Scene from "../components/Chapter1Scene.vue";
  export default {
    name: 'Chapter1Component',
    components:{
      Chapter1Scene,
      Header
    },
     setup () {
    useMeta({
      title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
 og:{
            title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
            description:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
            image:"https://foundingday.sa/foundingstory/assets/chapter1-thumbnail.jpg"
        },
            twitter:{
          site: "@Darahfoundation",
            creator:"@Darahfoundation",
            card:'summary_large_image',
            site_name:'تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز'
        },
      meta: [
        ...( [
          {
            vmid: "description",
            name: "description",
            content:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
          },
          {
            property: "og:description",
            content:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
          },
          {
            vmid: "image",
            name: "image",
            content:"https://foundingday.sa/foundingstory/assets/chapter1-thumbnail.jpg",
          },
           {
            property: "og:image",
            content:"https://foundingday.sa/foundingstory/assets/chapter1-thumbnail.jpg",
          },
        ]
        ),

      ],
    })
  },
     mounted() {}
  }
</script>



<style lang="scss">

</style>



