<template>
<metainfo>
    <template v-slot:title="{ content }">{{ content}}</template>
  </metainfo>
  <Header></Header>
<Chapter3Scene />

<div class="screen-orientation" id="screenOrientation">
<div class="screen-orientation-container">
<div class="screen-icon"><img src="../assets/rotate-device.svg" /></div>
<div class="message">الرجاء إعادة الشاشة للوضع العامودي</div>
</div>
</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import Header from '../components/Header.vue'
import Chapter3Scene from "../components/Chapter3Scene.vue";

  export default {
    name: 'Chapter3Component',
    components:{
      Chapter3Scene,
      Header
    },
           setup () {
    useMeta({
      title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
      og:{
            title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
            description:"الفصل الثالث: المؤسس الإمام محمد بن سعود  سنةً بعد الأخرى، وتحلّ المشاكل والتحديات",
            image:"https://foundingday.sa/foundingstory/assets/chapter3-thumbnail.jpg"
        },
            twitter:{
          site: "@Darahfoundation",
            creator:"@Darahfoundation",
            card:'summary_large_image',
            site_name:'تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز'
        },
      meta: [
        ...( [
          {
            vmid: "description",
            name: "description",
            content:"الفصل الثالث: المؤسس الإمام محمد بن سعود  سنةً بعد الأخرى، وتحلّ المشاكل والتحديات",
          },
          {
            vmid: "image",
            name: "image",
            content:"https://foundingday.sa/foundingstory/assets/chapter3-thumbnail.jpg",
          }
        ]
        ),

      ],
    })
  },
  }
</script>



<style lang="scss">

</style>



