import * as THREE from 'three';
import { loadGLTF, loadTexture, loadAudio } from "../../utils/asyncLoaders";
import gsap from "gsap";
import TreesScene from './TreesScene';
import emitter from 'tiny-emitter/instance';
import { clamp } from 'three/src/math/MathUtils';
import { setActiveHotspot } from './HotspotsPositions';
import { isMobile } from '../../utils/device';

class DesertScene {
    constructor() {
        this.bind();
        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer;
        this.sheperdAnimationMixer;
        this.obeidLastAnimationMixer;

        this.gltfScene;
        this.count = 1;
        this.sceneLineMesh;
        this.sceneLineMesh2;
        this.lineMesh;
        this.lineMesh2;

        this.scene1_obeid;
        this.phase = 0;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.objectsToRemove = [];

        this.phase2Mixer;
        this.phase3Mixer;
        this.phase4Mixer;
        this.phase5Mixer;
        this.phase6Mixer;

        this.mixer;
        this.activeAction;
        this.cameraPos;
        this.camera;
        this.renderer;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.mixer;
        this.modelReady = false
        this.animationActions = []
        this.activeAction;
        this.lastAction;
        this.animationsFolder;



        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = true;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;


        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);
        this.crowdAudio = new THREE.Audio(this.audioListener);
        this.peopleDisappearing = new THREE.Audio(this.audioListener);
        this.growingPlants = new THREE.Audio(this.audioListener);
        this.phase3AudioPlayed = false;

        this.stats;
        this.animationSpeed = 1;

        this.raycaster;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);
        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.leaving_convoy;
        this.textures;
        this.trees = [];
        this.bushes = [];
        this.palms = [];

        this.buildings = []

        this.fpsSpeed = 60;
        this.village1;
        this.village1_population;
        this.village2;
        this.village2_population;
        this.base_stone;
        this.landscape_build;
        this.farming;
        this.cliff_rocks;
        this.palms_last;


        this.horseAnimation;
        this.character1Animation;
        this.character2Animation;


        this.speedPhase1 = 0.0084;
        this.speedPhase2 = 0.003;
        this.speedPhase3 = 0.009;
        this.speedPhase4 = 0.003;
        this.speedPhase5 = 0.006;
        this.speedPhase6 = 0.012;

        this.voiceVolume = 0.5;
        this.bgVolume = 0.1;
        this.desertWindVolume = 0.6;

        emitter.on('startExperience2', () => {
            document.getElementById('scrollAction').classList.add('show')

            document.getElementById('actionIntro').classList.remove('show');
            this.phase = 2;
            this.setUpActions(2)
        })
        emitter.on('updateAudio', (isAudioOn) => {
            //  console.log(isAudioOn)

            if (isAudioOn) {

                this.bgAudio.setVolume(0.1);
                this.phase1Audio.setVolume(0.5)
                this.phase2Audio.setVolume(0.5)
                this.phase3Audio.setVolume(0.5)
                this.phase4Audio.setVolume(0.5)
                this.phase5Audio.setVolume(0.5)
                this.desertWindAudio.setVolume(0.6)
                this.crowdAudio.setVolume(0.2)
                this.peopleDisappearing.setVolume(0.2)
                this.growingPlants.setVolume(0.8)

            }
            else {
                this.bgAudio.setVolume(0);
                this.phase1Audio.setVolume(0)
                this.phase2Audio.setVolume(0)
                this.phase3Audio.setVolume(0)
                this.phase4Audio.setVolume(0)
                this.phase5Audio.setVolume(0)
                this.desertWindAudio.setVolume(0)
                this.crowdAudio.setVolume(0)
                this.peopleDisappearing.setVolume(0)
                this.growingPlants.setVolume(0)

            }
        });

        this.village1_greenery;
        this.village2_greenery


        this.raycaster;
        this.character1;


        emitter.on('pauseScene', (isPaused) => {
            if (isPaused) {
                this.pauseScene()
            }
            else {
                this.resumeScene()
            }
        })

    }




    async init(scene, camera, renderer, stats) {
        this.stats = stats;
        this.scene = scene
        this.camera = camera
        this.renderer = renderer

        this.raycaster = new THREE.Raycaster()
        this.raycaster.near = 180



        var audiosToBeLoaded = [
            loadAudio('../models/chapter2/audio/bg.mp3'),
            loadAudio('../models/chapter2/audio/phase1.mp3'),
            loadAudio('../models/chapter2/audio/phase2.mp3'),
            loadAudio('../models/chapter2/audio/phase3.mp3'),
            loadAudio('../models/chapter2/audio/phase4.mp3'),
            loadAudio('../models/chapter2/audio/phase5.mp3'),
            loadAudio('../models/chapter2/audio/DesertWind.mp3'),
            loadAudio('../models/chapter2/audio/crowd.mp3'),
            loadAudio('../models/chapter2/audio/people-disappearing.mp3'),
            loadAudio('../models/chapter2/audio/growing-plants.mp3')

        ]

        if (window.location.pathname.includes("en")){audiosToBeLoaded = [
            loadAudio('../models/chapter2/audio/bg.mp3'),
            loadAudio('../models/chapter2/audio/en/phase1.aac'),
            loadAudio('../models/chapter2/audio/en/phase2.aac'),
            loadAudio('../models/chapter2/audio/en/phase3.aac'),
            loadAudio('../models/chapter2/audio/en/phase4.aac'),
            loadAudio('../models/chapter2/audio/en/phase5.aac'),
            loadAudio('../models/chapter2/audio/DesertWind.mp3'),
            loadAudio('../models/chapter2/audio/crowd.mp3'),
            loadAudio('../models/chapter2/audio/people-disappearing.mp3'),
            loadAudio('../models/chapter2/audio/growing-plants.mp3')
        ]} 
        let audios = await Promise.all(audiosToBeLoaded);
        this.setUpAudio(audios);

        var texturesToBeLoaded = [
            loadTexture('../models/chapter2/scene/mats/brick.png'),
            loadTexture('../models/chapter2/scene/mats/bush.png'),
            loadTexture('../models/chapter2/scene/mats/convoy-b.png'),
            loadTexture('../models/chapter2/scene/mats/convoy-multiple-last.png'),
            loadTexture('../models/chapter2/scene/mats/desert-land.jpg'),
            loadTexture('../models/chapter2/scene/mats/door.jpg'),
            loadTexture('../models/chapter2/scene/mats/garden.png'),
            loadTexture('../models/chapter2/scene/mats/grass_1.jpg'),
            loadTexture('../models/chapter2/scene/mats/house1.png'),
            loadTexture('../models/chapter2/scene/mats/house2.png'),
            loadTexture('../models/chapter2/scene/mats/main-land.jpeg'),
            loadTexture('../models/chapter2/scene/mats/mult-palm.png'),
            loadTexture('../models/chapter2/scene/mats/p1.png'),
            loadTexture('../models/chapter2/scene/mats/p2.png'),
            loadTexture('../models/chapter2/scene/mats/p3.png'),
            loadTexture('../models/chapter2/scene/mats/p4.png'),
            loadTexture('../models/chapter2/scene/mats/p5.png'),
            loadTexture('../models/chapter2/scene/mats/p6.png'),
            loadTexture('../models/chapter2/scene/mats/palm-tree2.png'),
            loadTexture('../models/chapter2/scene/mats/palm.png'),
            loadTexture('../models/chapter2/scene/mats/palm2.png'),
            loadTexture('../models/chapter2/scene/mats/palm3.png'),
            loadTexture('../models/chapter2/scene/mats/rock.jpg'),
            loadTexture('../models/chapter2/scene/mats/running-horse.png'),
            loadTexture('../models/chapter2/scene/mats/tree.png'),
            loadTexture('../models/chapter2/scene/mats/walls-houses1.jpg'),
            loadTexture('../models/chapter2/scene/mats/walls-houses2.jpg'),
            loadTexture('../models/chapter1/scene/mats/dunes-diffuse.jpg'),
            loadTexture('../models/chapter1/scene/mats/detail.png'),
            loadTexture('../models/chapter1/scene/mats/detail.png'),
            loadTexture('../models/chapter1/scene/mats/dash.png'),
            loadTexture('../models/chapter1/scene/mats/line.jpg'),
            loadTexture('../models/chapter2/scene/mats/clothes-shep.png'),
            loadTexture('../models/chapter2/scene/mats/dressobeidmod.png'),
            loadTexture('../models/chapter2/scene/mats/face-shep.jpg'),
            loadTexture('../models/chapter2/scene/mats/face.jpg'),
            loadTexture('../models/chapter2/scene/mats/ghutra-shep.jpg'),
            loadTexture('../models/chapter2/scene/mats/ghutra4.jpg'),

        ]
        this.textures = await Promise.all(texturesToBeLoaded);
        await this.addLine2(this.textures);

        let modelUrl = isMobile() ? "../models/chapter2/scene/scene_mobile-baked.glb" : "../models/chapter2/scene/scene-baked.glb"
        var gltf = await loadGLTF(modelUrl);
        this.gltfScene = gltf.scene;
        this.setUpActions();
        this.setUpCamera(gltf)
        await this.setUpTextures(gltf, this.textures);
        await this.addLine(this.textures);



        await this.loadHorse(this.textures);
        await this.loadCharacter2(this.textures)



        if (isMobile()) {
            window.addEventListener('touchmove', this.handleTouchMove.bind(this), false);

        }
        else {

            window.addEventListener("wheel", (e) => {
                if (this.lastTime) {
                    const timeDelta = Date.now() - this.lastTime;
                    if (timeDelta < this.MAX_SCROLL_TIME_DELTA) return;
                }
                this.lastTime = Date.now();

                if (this.transitioning) return;
                const d = clamp(e.deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

                this.handleScroll(d * this.SCROLL_SCALAR)



            }, { passive: false });
        }

    }

    pauseScene() {
        this.pause = true
        this.pauseAudio()
    }
    resumeScene() {
        this.pause = false
        this.resumeAudio()
    }

    resumeAudio() {
        // if (this.phase == 1) {
        //     this.phase2Audio.play()
        // }
        // if (this.phase == 2) {
        //     this.phase1Audio.play()
        // }
        // if (this.phase == 3) {
        //     //   this.phase2Audio.play()
        // }
        // if (this.phase == 4 && !this.phase3AudioPlayed) {
        //     this.phase3Audio.play()
        // }
        // if (this.phase == 5) {
        //     this.phase5Audio.play()
        // }
        // if (this.phase == 6) {
        //     this.phase6Audio.play()
        //   }
    }
    pauseAudio() {
        this.phase1Audio.pause()
        this.phase2Audio.pause()
        this.phase3Audio.pause()
        this.phase4Audio.pause()
        this.phase5Audio.pause()
        this.phase6Audio.pause()

    }

    setUpAudio(audios) {


        this.bgAudio.setBuffer(audios[0]);
        this.bgAudio.setLoop(true);
        this.bgAudio.setVolume(0.1);
        this.bgAudio.pause();

        this.phase1Audio.setBuffer(audios[1]);
        this.phase1Audio.setPlaybackRate(1.1)
        this.phase1Audio.setLoop(false);
        this.phase1Audio.setVolume(0.5);
        this.phase1Audio.pause();

        this.phase2Audio.setBuffer(audios[2]);
        this.phase2Audio.setPlaybackRate(1.1)
        this.phase2Audio.setLoop(false);
        this.phase2Audio.setVolume(0.5);
        this.phase2Audio.pause();

        this.phase3Audio.setBuffer(audios[3]);
        this.phase3Audio.setPlaybackRate(1.1)
        this.phase3Audio.setLoop(false);
        this.phase3Audio.setVolume(0.5);
        this.phase3Audio.pause();
        this.phase3Audio.onEnded = () => {
            this.phase3AudioPlayed = true;
        }

        this.phase4Audio.setBuffer(audios[4]);
        this.phase4Audio.setPlaybackRate(1.1)
        this.phase4Audio.setLoop(false);
        this.phase4Audio.setVolume(0.5);
        this.phase4Audio.pause();

        this.phase5Audio.setBuffer(audios[5]);
        this.phase5Audio.setPlaybackRate(1.1)
        this.phase5Audio.setLoop(false);
        this.phase5Audio.setVolume(0.5);
        this.phase5Audio.pause();


        this.desertWindAudio.setBuffer(audios[6]);
        this.desertWindAudio.setLoop(true);
        this.desertWindAudio.setVolume(0.6);
        this.desertWindAudio.pause();

        this.crowdAudio.setBuffer(audios[7]);
        this.crowdAudio.setLoop(false);
        this.crowdAudio.setVolume(0.2);
        this.crowdAudio.pause();

        this.peopleDisappearing.setBuffer(audios[8]);
        this.peopleDisappearing.setLoop(false);
        this.peopleDisappearing.setVolume(0.2);
        this.peopleDisappearing.pause();

        this.growingPlants.setBuffer(audios[9]);
        this.growingPlants.setLoop(false);
        this.growingPlants.setVolume(0.8);
        this.growingPlants.pause();
    }


    async setUpTextures(gltf, textures) {
        this.village1 = this.gltfScene.getObjectByName('village_1');
        this.village1_population = this.gltfScene.getObjectByName('village_1_population_silhouettes');
        this.village2 = this.gltfScene.getObjectByName('village_2');
        this.village2_population = this.gltfScene.getObjectByName('village_2_population');
        this.leaving_convoy = this.gltfScene.getObjectByName('leaving_convoy');
        this.base_stone = this.gltfScene.getObjectByName('base_stone');
        this.landscape_build = this.gltfScene.getObjectByName('landscape_build1');
        this.farming = this.gltfScene.getObjectByName('farming');
        this.cliff_rocks = this.gltfScene.getObjectByName('cliff_rocks');
        this.palms_last = this.gltfScene.getObjectByName('palms_last');

        this.village1_greenery = this.gltfScene.getObjectByName('greenery001')
        this.village2_greenery = this.gltfScene.getObjectByName('greenery')
        this.village2_greenery.visible = false;

        //   village1_greenery.visible = false;

        this.palms_last.visible = false;
        this.village1_population.visible = false;
        this.village2.visible = false;
        this.village2_population.visible = false;
        this.leaving_convoy.visible = false;
        this.base_stone.visible = false;
        this.landscape_build.visible = false;
        this.farming.visible = false;
        this.cliff_rocks.visible = false;

        this.phase1Group.push(this.village1)
        this.phase1Group.push(this.village1_population)
        this.phase1Group.push(this.village1_greenery)

        this.phase4Group.push(this.village2)
        this.phase4Group.push(this.village2_population)
        this.phase4Group.push(this.farming)

        this.phase5Group.push(this.base_stone)
        this.sceneCamera = gltf.scene.children[0];
        this.sceneCamera1 = new THREE.Camera();

        this.village1_greenery.visible = false;


        this.leaving_convoy.visible = false;
        this.leaving_convoy.traverse(t => {

            if (t.material) {

                t.position.setY(0)
            }
            if (t.material) {
                t.material.opacity = 0
            }
        })



        //  this.sceneCamera = gltf.cameras[0];
        await new Promise((resolve, reject) => {
            gltf.scene.traverse(t => {


                if (t.material && t.parent.name.includes('village_1_population_silhouettes')) {

                    t.material.alphaTest = 0.1;
                    t.material.alphaToCoverage = true;
                    gsap.to(t.material, { opacity: 0, duration: 0 });
                    if (t.name.includes('Plane24_1') || t.name.includes('Plane23_1') || t.name.includes('Plane22_1') || t.name.includes('Plane21_1')) {
                        t.material.map = this.textures[17];
                        //  t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5

                    }
                    if (t.name.includes('Plane650') || t.name.includes('Plane651') || t.name.includes('Plane652') || t.name.includes('Plane653') ||
                        t.name.includes('Plane517') || t.name.includes('Plane518') || t.name.includes('Plane519') || t.name.includes('Plane520')) {
                        t.material.map = this.textures[17];
                        //  t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5

                    }
                    if (t.name.includes('Plane20_1') || t.name.includes('Plane19_1') || t.name.includes('Plane18_1') || t.name.includes('Plane17_1') || t.name.includes('Plane16_1')
                        || t.name.includes('Plane15_1') || t.name.includes('Plane14_1') || t.name.includes('Plane13_1') || t.name.includes('Plane12_1')) {
                        t.material.map = this.textures[14];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5

                    }
                    if (t.name.includes('Plane654') || t.name.includes('Plane655') || t.name.includes('Plane656') || t.name.includes('Plane657') || t.name.includes('Plane658')
                        || t.name.includes('Plane659') || t.name.includes('Plane660') || t.name.includes('Plane661') || t.name.includes('Plane662')
                        ||
                        t.name.includes('Plane521') || t.name.includes('Plane522') || t.name.includes('Plane523') || t.name.includes('Plane524') || t.name.includes('Plane525')
                        || t.name.includes('Plane526') || t.name.includes('Plane527') || t.name.includes('Plane528') || t.name.includes('Plane529')) {
                        t.material.map = this.textures[14];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5

                    }

                    if (t.name.includes('Plane11_1') || t.name.includes('Plane10_1')) {
                        t.material.map = this.textures[16];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane663') || t.name.includes('Plane662')
                        ||
                        t.name.includes('Plane530') || t.name.includes('Plane531')) {
                        t.material.map = this.textures[16];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane9_1') || t.name.includes('Plane8_2') || t.name.includes('Plane7_2')) {
                        t.material.map = this.textures[17];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane664') || t.name.includes('Plane665') || t.name.includes('Plane666')
                        ||
                        t.name.includes('Plane532') || t.name.includes('Plane533') || t.name.includes('Plane534')) {
                        t.material.map = this.textures[17];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane9_1') || t.name.includes('Plane672') || t.name.includes('Plane673')) {
                        t.material.map = this.textures[17];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane667') || t.name.includes('Plane668') || t.name.includes('Plane669')
                        ||
                        t.name.includes('Plane535') || t.name.includes('Plane536') || t.name.includes('Plane537')) {
                        t.material.map = this.textures[17];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane5_2') || t.name.includes('Plane4_2')) {
                        t.material.map = this.textures[15];
                        // t.material.alphaTest = 1;
                        // t.material.transparent = true
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane670') || t.name.includes('Plane671')
                        ||
                        t.name.includes('Plane538') || t.name.includes('Plane539')) {
                        t.material.map = this.textures[15];
                        // t.material.alphaTest = 1;
                        // t.material.transparent = true
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane5_2') || t.name.includes('Plane4_2')) {
                        t.material.map = this.textures[15];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane672') || t.name.includes('Plane673')
                        ||
                        t.name.includes('Plane540') || t.name.includes('Plane541')) {
                        t.material.map = this.textures[15];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                    if (t.name.includes('Plane3_2') || t.name.includes('Plane3_235') || t.name.includes('Plane1_91') || t.name.includes('Plane_294')) {
                        t.material.map = this.textures[12];
                        // t.material.alphaTest = 1;
                        t.material.transparent = false
                        t.material.side = THREE.DoubleSide;
                        t.material.metalness = 0.5
                    }
                }

                if (t.name.includes('Plane2_223') || t.name.includes('Plane2_216') || t.name.includes('Plane2_182') || t.name.includes('Plane649') || t.name.includes('Plane516')) {
                    t.material.map = this.textures[0];
                    t.material.alphaTest = 0.1;
                    t.material.alphaToCoverage = true;
                    // t.material.alphaTest = 1;
                    t.material.transparent = false
                    t.material.side = THREE.DoubleSide;
                    t.material.metalness = 0.5
                }

                t.castShadow = false;
                t.receiveShadow = false;
                t.matrixAutoUpdate = false;

                if (t.isMesh) {
                    if (t.name == 'convoy_last') {
                        this.convoyLast = t;
                        this.convoyLast.visible = false;
                    }
                }

                if (t.material && t.name.includes('bush')) {
                    t.material.map = this.textures[1];
                    t.material.depthWrite = false;
                    t.material.transparent = true
                    t.material.color.r = 0.800000011920929;
                    t.material.color.g = 0.800000011920929;
                    t.material.color.b = 0.800000011920929;
                    t.material.depthWrite = false;

                    t.updateMatrix();
                }

                if (t.material && t.material.name.includes('palm')) {
                    t.material.map = this.textures[19];
                    t.material.transparent = true;
                    t.material.side = THREE.DoubleSide
                }

                if (t.material && t.name.includes('messenger_silhouette')) {
                    t.material.map = this.textures[23];
                    t.material.transparent = true;
                    t.material.side = THREE.DoubleSide
                }
                if (t.material && t.name.includes('main_desert_platform2')) {
                    t.material.map = this.textures[4];
                    t.material.metalness = 0
                    t.material.color = new THREE.Color('#ffffff')
                    t.material.toneMapped = false;
                }
                if (t.material && t.name.includes('convoy')) {
                    t.material.map = this.textures[2];
                    t.material.transparent = true;
                }
                if (t.material && t.material.name.includes('convoy2')) {
                    t.material.map = this.textures[3];
                    t.material.transparent = true;
                }
                if (t.material && t.name.includes('leaving_convoy')) {
                    t.material.map = this.textures[3];
                    t.material.transparent = true;

                }
                if (t.material && t.material.name.includes('door')) {
                    t.material.map = this.textures[5];
                }
                if (t.material && t.material.name.includes('walls n houses')) {
                    t.material.map = this.textures[25];
                    t.material.metalness = 0.3;
                }



                if (t.material && t.material.name.includes('tree')) {
                    t.material.map = textures[24];
                    t.side = THREE.DoubleSide
                    t.material.depthWrite = false;
                    t.material.transparent = true
                    t.material.color.r = 0.800000011920929;
                    t.material.color.g = 0.800000011920929;
                    t.material.color.b = 0.800000011920929;
                    t.material.depthWrite = false;

                    t.updateMatrix();
                }


                if (t.name.includes('Cube')) {

                    this.objectsToRemove.push(t)
                    t.geometry.dispose();
                    t.material.dispose();
                    t.visible = false;
                    t.clear()


                    gltf.scene.remove(t)
                }

                t.updateMatrix();
            })
            resolve(true)
        })


        this.objectsToRemove.map(t => {
            t.parent.remove(t);
        })



        let geo;
        let geoBush = null;
        let geoTree = null;
        this.village1_greenery.traverse(t => {
            if (t.name.includes('tree')) {
                t.traverse(b => {
                    if (geoTree == null)
                        geoTree = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.trees.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('bush')) {
                t.traverse(b => {
                    if (geoBush == null)
                        geoBush = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);

                    this.bushes.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('palm')) {

                t.traverse(b => {
                    geo = b.geometry;
                    var position = new THREE.Vector3();

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.palms.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
        })
        await TreesScene.init(this.scene, this.camera, geo, this.bushes, geoBush, this.trees, geoTree)
        gltf.scene.remove(this.village1_greenery)

        this.bushes = [];
        this.trees = [];
        this.palms = []

        this.landscape_build.traverse(t => {

            if (t.material) {
                gsap.to(t.material, { opacity: 0, duration: 0 })
            }

            if (t.material && t.name == 'Plane35') {
                t.material.map = this.textures[21];
                t.material.metalness = 0.5
                t.material.transparent = true;
            }
            if (t.material && t.name == 'Plane61') {
                t.material.map = this.textures[6];
                t.material.transparent = true;
                t.material.metalness = 0.5
            }
            if (t.material && t.name == 'Plane69') {
                t.material.map = this.textures[11];
                t.material.transparent = true;
                t.material.metalness = 0.5
            }
            if (t.material && t.name == 'Plane5') {
                t.material.map = this.textures[8];
                t.material.transparent = true;
                t.material.metalness = 0.5
            }
            if (t.material && t.name == 'Plane8') {
                t.material.map = this.textures[9];
                t.material.transparent = true;
                t.material.metalness = 0.5
            }
            if (t.material && t.name == 'Plane21') {
                t.material.map = this.textures[20];
                t.material.transparent = true;
                t.material.metalness = 0.5
            }
        })
        this.village2_greenery.traverse(t => {
            if (t.name.includes('tree')) {
                t.traverse(b => {
                    if (geoTree == null)
                        geoTree = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.trees.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('bush')) {
                t.traverse(b => {
                    if (geoBush == null)
                        geoBush = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.bushes.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('palm')) {

                t.traverse(b => {
                    geo = b.geometry;
                    var position = new THREE.Vector3();

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.palms.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
        })
        await TreesScene.init2(this.scene, this.camera, geo, this.bushes, geoBush, this.trees, geoTree)



        this.bushes = [];
        this.trees = [];
        this.palms = []

        // farming.traverse(t => {
        //     t.visible = true
        // })

        this.farming.traverse(t => {
            if (t.name.includes('tree')) {
                t.visible = false;
                t.traverse(b => {
                    if (geoTree == null)
                        geoTree = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.trees.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('bush')) {
                t.visible = false;
                t.traverse(b => {
                    if (geoBush == null)
                        geoBush = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.bushes.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('palm')) {
                t.visible = false;

                t.traverse(b => {
                    geo = b.geometry;
                    var position = new THREE.Vector3();

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.palms.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.material && t.name.includes('Plane')) {
                t.material.map = this.textures[7];
                t.material.metalness = 0.5;

            }
        })
        await TreesScene.init3(this.scene, this.camera, geo, this.bushes, geoBush, this.trees, geoTree)


        gltf.scene.remove(this.village1_greenery)
        this.scene.add(gltf.scene)
        this.village1_greenery.clear()
        this.village2_greenery.clear()
        this.palms_last.clear()
        this.farming.clear()
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld()


    }

    setUpCharactersAnimation(gltf) {
        let gltfAnimation = gltf.animations[0];
        let tracks = gltf.animations[0].tracks;

        let trackObeid = [tracks[3], tracks[4], tracks[5], tracks[6], tracks[7], tracks[8], tracks[9], tracks[10], tracks[11]];

        let animationCamera = gltfAnimation;
        animationCamera.tracks = trackObeid;
        this.obeidAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t = this.obeidAnimationMixer.clipAction(animationCamera);
        t.clampWhenFinished = true;
        t.play();
        this.obeidAnimationMixer.update(16)



        let trackSheperd = [tracks[21], tracks[22], tracks[23], tracks[24]];
        let animationSheperd = gltfAnimation;
        animationCamera.tracks = trackSheperd;
        this.sheperdAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t2 = this.sheperdAnimationMixer.clipAction(animationSheperd);
        t2.clampWhenFinished = true;
        t2.play();
        this.sheperdAnimationMixer.update(90)

        let trackObeidLast = [tracks[12], tracks[13], tracks[14], tracks[15], tracks[16], tracks[17], tracks[18], tracks[19], tracks[20]];
        let animationObeidLast = gltfAnimation;
        animationCamera.tracks = trackObeidLast;
        this.obeidLastAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t3 = this.obeidLastAnimationMixer.clipAction(animationObeidLast);
        t3.clampWhenFinished = true;
        t3.play();
        this.obeidLastAnimationMixer.update(96)

    }
    async setUpCamera(gltf) {
        loadGLTF('../models/chapter2/cams/cam3.glb').then(gltf => {

            let cameraMixerPhase3 = new THREE.AnimationMixer(this.camera);
            this.phase3Mixer = cameraMixerPhase3;

            let t = this.phase3Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            this.phase3Mixer.update(0)
            t.setLoop(THREE.LoopOnce);
            t.play();
        })

        loadGLTF('../models/chapter2/cams/cam5.glb').then(gltf => {
            let cameraMixerPhase5 = new THREE.AnimationMixer(this.camera);
            this.phase5Mixer = cameraMixerPhase5;

            let t = this.phase5Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter2/cams/cam6.glb').then(gltf => {
            let cameraMixerPhase6 = new THREE.AnimationMixer(this.camera);
            this.phase6Mixer = cameraMixerPhase6;

            let t = this.phase6Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter2/cams/cam2.glb').then(gltf => {
            this.sceneCamera1 = new THREE.Camera()
            let cameraMixerPhase2 = new THREE.AnimationMixer(this.sceneCamera1);
            this.phase2Mixer = cameraMixerPhase2;

            let t2 = this.phase2Mixer.clipAction(gltf.animations[0]);
            t2.clampWhenFinished = true;
            t2.setLoop(THREE.LoopOnce);
            t2.play();


            this.phase2Mixer.update(0.1)
            gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 0 })
            gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 0 })


        })
        loadGLTF('../models/chapter2/cams/cam4.glb').then(gltf => {
            let cameraMixerPhase4 = new THREE.AnimationMixer(this.sceneCamera);
            this.phase4Mixer = cameraMixerPhase4;

            let t = this.phase4Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
            this.phase4Mixer.update(0.1);

        })
    }


    setUpActions(phaseNumber) {
        switch (phaseNumber) {

            case 2:
                //     document.getElementById('action1').classList.remove('show')
                this.phase = 2;
                this.playPhase2 = true;
                this.playPhase1 = false;

                this.lineMesh2.visible = true;
                this.sceneLineMesh2.visible = true;


                this.phase1Audio.play();
                break;

            case 3:
                document.getElementById('action2').classList.remove('show')
                this.phase = 3;
                this.playPhase2 = false;
                this.playPhase3 = true;
                this.phase1Audio.pause();
                this.phase2Audio.play();
                this.village1_population.visible = true;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                setTimeout(() => {
                    this.village1_population.traverse(t => {
                        if (t.material) {
                            gsap.to(t.material, { opacity: 1, duration: 3, delay: 1 });
                        }
                    })
                    this.crowdAudio.play();

                }, 2000)


                this.leaving_convoy.visible = true;

                //   setTimeout(() => {
                this.leaving_convoy.traverse(t => {
                    if (t.material) {
                        gsap.to(t.material, { opacity: 1, duration: 1, delay: 15 });
                    }
                })


                this.phase3Group.forEach(t => {
                    setTimeout(() => {
                        t.visible = true;
                    }, 200)

                })

                break;
            case 4:
                //    document.getElementById('action3').classList.remove('show')
                this.phase = 4;
                this.camera.position.set(-2950.841015943198, 321.5404176792247, -4561.779705812856)
                this.camera.quaternion.set(-0.02913308166160366, -0.9991744565082294, -0.01751713540750889, 0.022245031481312016)
                this.camera.rotation.set(-3.105248885686712, -0.04344634502894, -0.01751713540750889, -3.0825051542354025)

                this.lineMesh.visible = true;
                this.sceneLineMesh.visible = true;
                this.playPhase4 = true;
                this.playPhase2 = false;

                setTimeout(() => {
                    this.peopleDisappearing.play()

                }, 4000)
                document.getElementsByClassName('story-container')[0].classList.remove('border')
                this.phase1Group.forEach(t => {
                    t.visible = false;
                })


                this.phase2Group.forEach(t => {
                    if (t && t.visible)
                        t.visible = false;
                })
                setTimeout(() => {
                    this.phase4Group.forEach(t => {
                        t.visible = true;
                    });
                }, 1000)


                break;
            case 5:
                document.getElementsByClassName('story-container')[0].classList.add('border')
                document.getElementById('action4').classList.remove('show')
                this.phase = 5;
                this.playPhase5 = true;
                this.playPhase4 = false;
                this.sheperdAnimation = true;

                this.phase3Audio.pause();
                this.phase4Audio.play();

                this.phase5Group.forEach(t => {
                    t.visible = true;
                })
                this.phase2Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()
                })

                break;

            case 6:
                document.getElementById('action5').classList.remove('show')
                this.phase = 6;
                this.playPhase6 = true;
                this.playPhase5 = false;
                this.obeidLastAnimation = true;

                this.phase4Group.forEach(t => {
                    t.visible = false;
                })
                this.landscape_build.visible = true

                var delay = 0;

                setTimeout(() => {
                    this.base_stone.traverse(t => {
                        if (t.material) {
                            gsap.to(t.material, { opacity: 0, duration: 0.5, delay: 0.2 });
                        }
                    })

                }, 1000)

                setTimeout(() => {
                    this.landscape_build.traverse(t => {
                        if (t.material) {
                            delay += 0.1;
                            gsap.to(t.material, { opacity: 1, duration: 0.5, delay: delay })
                        }
                    })
                }, 2000)

                setTimeout(() => { this.growingPlants.play() }, 3000)

                this.phase4Audio.pause();
                this.phase5Audio.play();

                this.renderer.dispose()
                break;
        }
    }


    async loadHorse(textures) {
        if (!isMobile()) {
            var horse = await loadGLTF("../models/chapter2/scene/horse1-baked.glb");
            this.phase2Group.push(horse.scene)
            horse.scene.visible = false;
            this.scene.add(horse.scene)
        }
        var character1 = await loadGLTF("../models/chapter2/scene/character1-baked.glb");

        this.character1 = character1.scene;
        character1.scene.traverse(t => {
            if (t.material) {
                if (t.name.includes('obeid')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[33],
                        name: t.material.name
                    })
                }
                if (t.name.includes('messenger_')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[33],
                        name: t.material.name
                    })
                }
            }
        })


        this.character1Animation = new THREE.AnimationMixer(character1.scene);
        let t2 = this.character1Animation.clipAction(character1.animations[0]);
        t2.clampWhenFinished = true;
        t2.play();
        this.character1Animation.update(9)


        this.phase2Group.push(character1.scene)
        character1.scene.visible = false;

        this.scene.add(character1.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }
    async loadCharacter2(textures) {
        //  var horse = await loadGLTF("../models/chapter2/scene/horse1.glb");
        var character2 = await loadGLTF("../models/chapter2/scene/character2-baked.glb");

        character2.scene.traverse(t => {

            if (t.material) {
                if (t.name.includes('obeid')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[33],
                        name: t.material.name
                    })
                }
                if (t.name.includes('cousin')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[33],
                        name: t.material.name
                    })
                }
            }
        })


        // console.l
        this.character2Animation = new THREE.AnimationMixer(character2.scene);
        let t2 = this.character2Animation.clipAction(character2.animations[0]);
        t2.clampWhenFinished = true;
        t2.play();
        this.character2Animation.update(33)

        character2.scene.autoUpdate = true;
        character2.scene.matrixAutoUpdate = true;


        this.phase4Group.push(character2.scene)
        character2.scene.visible = false;

        this.scene.add(character2.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }



    checkFrustum() {
        if (this.phase == 2 && this.character1 && this.camera.position.distanceTo(this.character1.position) < 6000) {
            //   setActiveHotspot(0)
        }

        else if ((this.phase == 3 || this.phase == 4) && (this.camera.position.distanceTo(this.leaving_convoy.position) < 5200 && this.camera.position.distanceTo(this.leaving_convoy.position) > 3000)) {
            setActiveHotspot(0)

        }
        else if ((this.phase == 4 || this.phase == 5) && (this.camera.position.distanceTo(this.village2.position) < 1000 && this.camera.position.distanceTo(this.village2.position) > 200)) {
            setActiveHotspot(1)

        }
        else if ((this.phase == 5) && (this.camera.position.distanceTo(this.base_stone.position) < 150)) {
            //      setActiveHotspot(1)

        }
        else {
            setActiveHotspot(-1)
        }


    }

    update(camera) {
        if (!this.pause) {
            //if (this.camera)
            this.checkFrustum()

            if (this.playPhase3 && this.phase == 3) {
                if (this.character1Animation.time < 12) {
                    this.scene.updateMatrixWorld()
                    this.character1Animation.update(0.05)
                }
                else {
                    if (this.phase3Mixer.time >= 0 && this.phase3Mixer.time <= 8) {
                        this.phase3Mixer.update(this.speedPhase3);
                    }
                    if (this.phase3Mixer.time > 8) {
                        document.getElementById('action3').classList.add('show')
                        emitter.emit('animateKnob3_2', true)
                        this.setUpActions(4)

                    }
                }
            }

            else if (this.playPhase5 && this.phase == 5) {
                //   if (this.sheperdAnimationMixer.time > 95) {
                //     this.sheperdAnimation = false;
                if (this.character2Animation.time < 37) {
                    this.character2Animation.update(0.01)
                }

                if (this.character2Animation.time >= 37) {
                    if (this.phase5Mixer.time >= 0 && this.phase5Mixer.time <= 4) {
                        //      this.phase5Mixer.update((0.005));
                        this.phase5Mixer.update((this.speedPhase5));

                    }
                    if (this.phase5Mixer.time < 0.1) {
                        this.phase5Mixer.update(0.1)
                    }
                    if (this.phase5Mixer.time > 4) {
                        //   this.phase5Mixer.setTime(2);
                        document.getElementById('action5').classList.add('show')
                        emitter.emit('animateKnob5_2', true)
                    }
                }
                // }
            }
            else if (this.playPhase6 && this.phase == 6) {
                // if (this.obeidLastAnimationMixer.time > 100) {
                this.obeidLastAnimation = false;
                if (this.phase6Mixer.time >= 0 && this.phase6Mixer.time <= 12) {
                    //    this.phase6Mixer.update((0.005));
                    this.phase6Mixer.update((this.speedPhase6));
                }
                if (this.phase6Mixer.time >= 12) {
                    emitter.emit('chapterDone', true)
                }
                if (this.phase6Mixer.time < 0.1) {
                    this.phase6Mixer.setTime(0.1)
                }
            }

        }
    }



    bind() {
        this.init = this.init.bind(this)
    }

    async addLine(textures) {
        var lineGltf = await loadGLTF("../models/chapter2/scene/line.glb");
        this.lineMesh = lineGltf.scene.children[0];

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh.geometry)
        lineGeo.setAttribute('position', this.lineMesh.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        this.lineMesh.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh.material.clone();
        lineMat.map = textures[30]


        this.sceneLineMesh = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh.material.map = textures[31]
        this.sceneLineMesh.position.set(this.lineMesh.position.x, this.lineMesh.position.y, this.lineMesh.position.z)
        this.sceneLineMesh.rotation.set(this.lineMesh.rotation.x, this.lineMesh.rotation.y, this.lineMesh.rotation.z)
        this.sceneLineMesh.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh)
        this.scene.add(this.lineMesh)


        this.lineMixer = new THREE.AnimationMixer(this.lineMesh);

        let t2 = this.lineMixer.clipAction(lineGltf.animations[0]);
        t2.clampWhenFinished = true;
        t2.setLoop(THREE.LoopOnce);
        t2.play();
        this.lineMixer.update(21);

        // this.sceneLineMesh.morphTargetInfluences[0] = 0.5;
        this.lineMesh.visible = false;
        this.sceneLineMesh.visible = false;
    }
    async addLine2(textures) {
        var lineGltf = await loadGLTF("../models/chapter2/line1_2.glb");

        this.lineMesh2 = lineGltf.scene.children[0];

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh2.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh2.geometry)
        lineGeo.setAttribute('position', this.lineMesh2.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        this.lineMesh2.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh2.material.clone();
        lineMat.map = textures[30]

        this.sceneLineMesh2 = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh2.material.map = textures[31]
        this.sceneLineMesh2.position.set(this.lineMesh2.position.x, this.lineMesh2.position.y, this.lineMesh2.position.z)
        this.sceneLineMesh2.rotation.set(this.lineMesh2.rotation.x, this.lineMesh2.rotation.y, this.lineMesh2.rotation.z)
        this.sceneLineMesh2.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh2)
        this.scene.add(this.lineMesh2)


        this.lineMixer2 = new THREE.AnimationMixer(this.lineMesh2);

        let t2 = this.lineMixer2.clipAction(lineGltf.animations[0]);

        t2.play();
        this.lineMixer2.update(0);

        this.lineMesh2.visible = false;
        this.sceneLineMesh2.visible = false;
    }

    handleMouseMove(e) {
        this.setPointer(e);

        this.delta.x = this.lastInputPosition.x - e.clientX;
        this.delta.y = this.lastInputPosition.y - e.clientY;

        this.lastInputPosition.set(e.clientX, e.clientY);
    }



    handleTouchMove(e) {
        const touch = e.changedTouches[0];

        this.delta.x = this.lastInputPosition.x - touch.clientX;
        this.delta.y = clamp(this.lastInputPosition.y - touch.clientY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        if (this.phase == 4) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)
        }
        else if (this.phase == 2) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE / 1.2)
        }

        this.lastInputPosition.set(touch.clientX, touch.clientY);

        this.resetScrollTime();

        if (this.selectedOnDown) {
            this.selectedOnDown.object.userData.object.mouseOut();
        }
    }



    handleScroll(deltaY) {

        const d = deltaY// ThreeMath.clamp(deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        if (this.phase == 4) {

            document.getElementById('action3').classList.remove('show')
            document.getElementById('scrollAction').classList.add('show')

            this.phase2Audio.pause();
            this.phase3Audio.play();

            if (this.lineMixer.time >= 21) {
                this.lineMixer.update(d * 1.3);
            }
            if (this.lineMixer.time < 21) {
                this.lineMixer.setTime(21)
            }

            if (this.phase4Mixer.time >= 0.1 && this.phase4Mixer.time <= 13) {
                this.phase4Mixer.update((d / 1.5));
                gsap.to(this.camera.position, { x: this.sceneCamera.position.x, y: this.sceneCamera.position.y, z: this.sceneCamera.position.z, duration: 0.5 })
                gsap.to(this.camera.quaternion, { x: this.sceneCamera.quaternion.x, y: this.sceneCamera.quaternion.y, z: this.sceneCamera.quaternion.z, w: this.sceneCamera.quaternion.w, duration: 0.5 })

            }
            if (this.phase4Mixer.time < 0.1) {
                //     this.phase4Mixer.update(0.1)
                this.phase4Mixer.update(0.1)
                this.phase4Mixer.setTime(0.1)
            }
            if (this.phase4Mixer.time > 13) {
                //    this.phase4Mixer.update(3);
                document.getElementById('action4').classList.add('show')
                emitter.emit('animateKnob4_2', true)
                document.getElementById('scrollAction').classList.remove('show')
            }

        }
        if (this.playPhase2) {
            if (this.phase == 2 && this.phase2Mixer) {

                //   if (this.obeidAnimationMixer.time > 20) {
                //        this.playObeidAnimation = false;
                if (this.phase2Mixer.time < 0.1) {
                    this.phase2Mixer.setTime(0.1)
                    this.phase2Mixer.update(0.1)


                }

                if (this.phase2Mixer.time > 3.5) {
                    //     this.phase2Mixer.setTime(3.4)
                    //  this.phase2Mixer.setTime(4.4)
                    document.getElementById('action2').classList.add('show')
                    emitter.emit('animateKnob2_2', true)
                    document.getElementById('scrollAction').classList.remove('show')
                }
                if (this.phase2Mixer.time >= 0 && this.phase2Mixer.time <= 3.5) {
                    //     this.phase2Mixer.update((0.003));
                    this.phase2Mixer.update(d / 2);
                    gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 1 })
                    gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 1 })
                    document.getElementById('scrollAction').classList.add('show')

                    if (this.camera.position.x > -2081) {
                        this.phase2Group.map(t => {
                            t.visible = true
                        })
                    }
                }
                //    }
            }
        }

        this.resetScrollTime();


    }

    resetScrollTime() {
        this.lastScrollTime = Date.now();
    }
    removeObject3D(object) {
        this.disposeHierarchy(object);

        if (!(object instanceof THREE.Object3D)) return false;
        // for better memory management and performance
        if (object.geometry) {
            object.geometry.dispose();
        }
        if (object.material) {
            console.log(object.material)
            if (object.material instanceof Array) {
                // for better memory management and performance
                object.material.forEach(material => material.dispose());
            } else {
                // for better memory management and performance
                object.material.dispose();
            }
        }
        object.traverse(t => {
            if (t.geometry) {
                t.geometry.dispose();
            }
            if (t.material) {
                if (t.material instanceof Array) {
                    // for better memory management and performance
                    t.material.forEach(material => material.dispose());
                } else {
                    // for better memory management and performance
                    t.material.dispose();
                }
            }
        })
        if (object.parent) {
            object.parent.remove(object);
        }
        this.renderer.clear();
        this.renderer.dispose()
        // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }
    disposeHierarchy(node, callback) {
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child = node.children[i];
            this.disposeHierarchy(child, callback);

            if (typeof callback === 'function') {
                callback(child);
            }
        }
    }



    start() {
        setTimeout(() => {
            this.pause = false;

            this.bgAudio.play();
            this.desertWindAudio.play()
            setTimeout(() => {
                this.phase1Audio.play();
            }, 3000)
            this.playPhase1 = true;

            if (this.phase == 0) {
                document.getElementById('actionIntro').classList.add('show')

            }

            this.fpsSpeed = this.stats.getFPS()
            console.log('fps', this.stats.getFPS())
            if (this.fpsSpeed > 60) {
                this.speedPhase1 = 0.0042;
                this.speedPhase2 = 0.0015;
                this.speedPhase3 = 0.004;
                this.speedPhase4 = 0.0015;
                this.speedPhase5 = 0.003;
                this.speedPhase6 = 0.006;
            }
        }, 200)
    }



    destroy() {

        this.cameraAnimationMixers && this.cameraAnimationMixers.length > 0 && this.cameraAnimationMixers[0].uncacheRoot(this.camera);
        this.phase2Mixer && this.phase2Mixer.uncacheRoot(this.camera);
        this.phase3Mixer && this.phase3Mixer.uncacheRoot(this.camera);
        this.phase4Mixer && this.phase4Mixer.uncacheRoot(this.camera);
        this.phase5Mixer && this.phase5Mixer.uncacheRoot(this.camera);
        this.phase6Mixer && this.phase6Mixer.uncacheRoot(this.camera);

        this.phase1Audio.pause();
        this.phase2Audio.pause();
        this.phase3Audio.pause();
        this.phase4Audio.pause();
        this.phase5Audio.pause();
        this.phase6Audio.pause();
        this.bgAudio.pause();
        this.desertWindAudio.pause();
        this.crowdAudio.pause()
        this.peopleDisappearing.pause();
        this.growingPlants.pause();
        //  this.footstepAudio.pause();

        this.desertWindAudio.pause();

        const cleanMaterial = material => {
            material.dispose()

            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    value.dispose()
                }
            }
        }


        this.gltfScene.traverse(object => {
            if (!object.isMesh) return

            object.geometry.dispose()

            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })



        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer = null;
        this.sheperdAnimationMixer = null;
        this.obeidLastAnimationMixer = null;

        //  this.gltfScene = null;
        this.count = 1;
        this.sceneLineMesh = null;
        this.lineMesh = null;

        this.scene1_obeid = null;
        this.phase = 1;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.phase2Mixer = null;
        this.phase3Mixer = null;
        this.phase4Mixer = null;
        this.phase5Mixer = null;
        this.phase6Mixer = null;

        this.mixer = null;
        this.activeAction = null;
        this.cameraPos = null;
        this.camera = null;
        this.renderer = null;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.modelReady = false
        this.animationActions = []
        this.activeAction = null;
        this.lastAction = null;
        this.animationsFolder = null;



        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.crowdAudio = new THREE.Audio(this.audioListener);
        this.peopleDisappearing = new THREE.Audio(this.audioListener);
        this.growingPlants = new THREE.Audio(this.audioListener);

        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.raycaster = null;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);

        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.textures.map(texture => {
            texture.dispose();
        })
        this.textures = [];


        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer;
        this.sheperdAnimationMixer;
        this.obeidLastAnimationMixer;

        this.gltfScene;
        this.count = 1;
        this.sceneLineMesh;
        this.lineMesh;

        this.scene1_obeid;
        this.phase = 2;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];



        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = true;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;


        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);
        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.leaving_convoy;
        this.textures;
        this.trees = [];
        this.bushes = [];
        this.palms = [];

        this.buildings = []


        this.village1 = null;
        this.village1_population = null;
        this.village2 = null;
        this.village2_population = null;
        this.base_stone = null;
        this.landscape_build = null;
        this.farming = null;
        this.cliff_rocks = null;
        this.palms_last = null;


        this.horseAnimation = null;
        this.character1Animation = null;
        this.character2Animation = null;




    }
}

const _instance = new DesertScene()
export default _instance