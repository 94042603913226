import * as THREE from 'three';
import { loadGLTF, loadTexture, loadAudio } from "../../utils/asyncLoaders";
import gsap from "gsap";
import TreesScene from './TreesScene';
import emitter from 'tiny-emitter/instance';
import { clamp } from 'three/src/math/MathUtils';
import { hotspots, setActiveHotspot } from './HotspotsPositions';
import { isMobile } from '../../utils/device';

class DesertScene {
    constructor() {
        this.bind();
        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer;
        this.sheperdAnimationMixer;
        this.obeidLastAnimationMixer;

        this.stats;
        this.fpsSpeed = 60;
        this.gltfScene;
        this.count = 1;
        this.sceneLineMesh;
        this.sceneLineMesh2;
        this.lineMesh;
        this.lineMesh2;
        this.scene1_obeid;
        this.phase = 0;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];
        this.phase1Mixer;
        this.phase2Mixer;
        this.phase3Mixer;
        this.phase4Mixer;
        this.phase5Mixer;
        this.phase6Mixer;

        this.mixer;
        this.activeAction;
        this.cameraPos;
        this.camera;
        this.renderer;

        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.mixer;
        this.modelReady = false
        this.animationActions = []
        this.activeAction;
        this.lastAction;
        this.animationsFolder;

        this.absScroll = 0;
        this.smoothProgress = 0;

        this.lastTime = 0;

        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = true;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.cam1;


        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.raycaster;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);
        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.leaving_convoy;
        this.textures;
        this.trees = [];
        this.bushes = [];
        this.palms = [];

        this.buildings = []


        this.village1;
        this.village1_population;
        this.village2;
        this.village2_population;
        this.base_stone;
        this.landscape_build;
        this.farming;
        this.cliff_rocks;
        this.palms_last;


        this.horseAnimation;
        this.character1Animation;
        this.character2Animation;
        this.flag = [];

        this.speedPhase1 = 0.03;
        this.speedPhase2 = 0.02;
        this.speedPhase3 = 0.04;
        this.speedPhase4 = 0.03;
        this.speedPhase5 = 0.0044;
        this.speedPhase6 = 0.002;

        this.voiceVolume = 0.5;
        this.bgVolume = 0.1;
        this.desertWindVolume = 0.6;

        //intersected objects
        this.tents
        this.convoy
        this.bush6_1;
        this.village;
        this.raycaster;
        emitter.on('pauseScene', (isPaused) => {
            if (isPaused) {
                this.pauseScene()
            }
            else {
                this.resumeScene()
            }
        })
        emitter.on('startExperience4', () => {
            document.getElementById('scrollAction').classList.add('show')

            document.getElementById('actionIntro').classList.remove('show');
            this.phase = 1;
            this.setUpActions(1)
        })
        emitter.on('updateAudio', (isAudioOn) => {

            if (isAudioOn) {

                this.bgAudio.setVolume(0.1);
                this.phase1Audio.setVolume(0.5)
                this.phase2Audio.setVolume(0.5)
                this.phase3Audio.setVolume(0.5)
                this.phase4Audio.setVolume(0.5)
                this.phase5Audio.setVolume(0.5)
                this.desertWindAudio.setVolume(0.6)

            }
            else {
                this.bgAudio.setVolume(0);
                this.phase1Audio.setVolume(0)
                this.phase2Audio.setVolume(0)
                this.phase3Audio.setVolume(0)
                this.phase4Audio.setVolume(0)
                this.phase5Audio.setVolume(0)
                this.desertWindAudio.setVolume(0)
            }
        });

    }




    async init(scene, camera, renderer, stats) {

        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.stats = stats

        this.raycaster = new THREE.Raycaster()
        //     this.raycaster.near = 80


        var audiosToBeLoaded = [
            loadAudio('../models/chapter4/audio/phase1.mp3'),
            loadAudio('../models/chapter4/audio/phase2.mp3'),
            loadAudio('../models/chapter4/audio/phase3.mp3'),
            loadAudio('../models/chapter4/audio/phase4.mp3'),
            loadAudio('../models/chapter4/audio/bg.mp3'),

        ]

        if (window.location.pathname.includes("en")){audiosToBeLoaded = [
            loadAudio('../models/chapter4/audio/en/phase1.aac'),
            loadAudio('../models/chapter4/audio/en/phase2.aac'),
            loadAudio('../models/chapter4/audio/en/phase3.aac'),
            loadAudio('../models/chapter4/audio/en/phase4.aac'),
            loadAudio('../models/chapter4/audio/bg.mp3'),
        ]}
        let audios = await Promise.all(audiosToBeLoaded);
        this.setUpAudio(audios);

        var texturesToBeLoaded = [
            loadTexture('../models/chapter4/scene/mats/al-sadu.jpg'),
            loadTexture('../models/chapter4/scene/mats/bush.png'),
            loadTexture('../models/chapter4/scene/mats/camels-coming.png'),
            loadTexture('../models/chapter4/scene/mats/camels-coming2.png'),
            loadTexture('../models/chapter4/scene/mats/camels-coming3.png'),
            loadTexture('../models/chapter4/scene/mats/desert-land.jpg'),
            loadTexture('../models/chapter4/scene/mats/door.jpg'),
            loadTexture('../models/chapter4/scene/mats/fence.png'),
            loadTexture('../models/chapter4/scene/mats/field1.png'),
            loadTexture('../models/chapter4/scene/mats/field2.png'),
            loadTexture('../models/chapter4/scene/mats/field3.png'),
            loadTexture('../models/chapter4/scene/mats/field4.png'),
            loadTexture('../models/chapter4/scene/mats/field5.png'),
            loadTexture('../models/chapter4/scene/mats/field6.png'),
            loadTexture('../models/chapter4/scene/mats/grass_1.jpg'),
            loadTexture('../models/chapter4/scene/mats/market6.png'),
            loadTexture('../models/chapter4/scene/mats/market7.png'),
            loadTexture('../models/chapter4/scene/mats/market10.png'),
            loadTexture('../models/chapter4/scene/mats/greenery-palms.png'),
            loadTexture('../models/chapter4/scene/mats/p1.png'),
            loadTexture('../models/chapter4/scene/mats/p2.png'),
            loadTexture('../models/chapter4/scene/mats/p4.png'),
            loadTexture('../models/chapter4/scene/mats/p5.png'),
            loadTexture('../models/chapter4/scene/mats/p6.png'),
            loadTexture('../models/chapter4/scene/mats/palm-tree2.png'),
            loadTexture('../models/chapter4/scene/mats/palm.png'),
            loadTexture('../models/chapter4/scene/mats/pattern1.jpg'),
            loadTexture('../models/chapter4/scene/mats/reception-ceiling.jpg'),
            loadTexture('../models/chapter4/scene/mats/rock.jpg'),
            loadTexture('../models/chapter4/scene/mats/round.jpg'),
            loadTexture('../models/chapter4/scene/mats/round2.png'),
            loadTexture('../models/chapter4/scene/mats/round3.png'),
            loadTexture('../models/chapter4/scene/mats/sil1-man.png'),
            loadTexture('../models/chapter4/scene/mats/teacher-wall.jpg'),
            loadTexture('../models/chapter4/scene/mats/tree.png'),
            loadTexture('../models/chapter4/scene/mats/visitor1.png'),
            loadTexture('../models/chapter4/scene/mats/visitor2.png'),
            loadTexture('../models/chapter4/scene/mats/visitor3.png'),
            loadTexture('../models/chapter4/scene/mats/walls-houses.jpg'),
            loadTexture('../models/chapter4/scene/mats/walls-houses2.jpg'),
            loadTexture('../models/chapter4/scene/mats/walls-houses1-1.jpg'),
            loadTexture('../models/chapter4/scene/mats/walls-houses_1.jpg'),
            loadTexture('../models/chapter4/scene/mats/reception-floor.jpg'),
            loadTexture('../models/chapter4/scene/mats/patttern2.jpg'),
            loadTexture('../models/chapter4/scene/mats/reception-wall.jpg'),
            loadTexture('../models/chapter4/scene/mats/recepetion-texture.jpg'),
            loadTexture('../models/chapter4/scene/mats/visitor-texture.jpg'),
            loadTexture('../models/chapter4/scene/mats/teacher-texture.jpg'),
            loadTexture('../models/chapter1/scene/mats/dash.png'),
            loadTexture('../models/chapter1/scene/mats/line.jpg'),


        ]
        this.textures = await Promise.all(texturesToBeLoaded);


        let modelUrl = isMobile() ? "../models/chapter4/scene/scene_mobile-baked.glb" : "../models/chapter4/scene/scene-baked.glb"
        var gltf = await loadGLTF(modelUrl);

        this.sceneCamera = gltf.cameras[0];
        this.sceneCamera1 = new THREE.Camera();
        this.gltfScene = gltf.scene;
        //    this.setUpActions();
        this.setUpCamera(gltf)
        // this.setUpCharactersAnimation(gltf)
        await this.setUpTextures(gltf, this.textures);
        this.scene.add(gltf.scene)
        await this.loadCharacter(this.textures)

        this.addSprite()
        await this.addLine2(this.textures);

        if (isMobile()) {
            window.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
        }
        else {

            window.addEventListener("wheel", (e) => {
                if (this.lastTime) {
                    const timeDelta = Date.now() - this.lastTime;
                    if (timeDelta < this.MAX_SCROLL_TIME_DELTA) return;
                }
                this.lastTime = Date.now();

                if (this.transitioning) return;
                const d = clamp(e.deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

                this.handleScroll(d * this.SCROLL_SCALAR)



            }, { passive: false });

        }

    }

    pauseScene() {
        this.pause = true
        this.pauseAudio()
    }
    resumeScene() {
        this.pause = false
        this.resumeAudio()
    }

    resumeAudio() {
        if (this.phase == 1) {
            this.phase1Audio.play()
        }
        if (this.phase == 2) {
            this.phase2Audio.play()
        }
        if (this.phase == 3) {
            this.phase3Audio.play()
        }
        if (this.phase == 4) {
            this.phase4Audio.play()
        }
        if (this.phase == 5) {
            this.phase5Audio.play()
        }
        if (this.phase == 6) {
            this.phase6Audio.play()
        }
    }
    pauseAudio() {
        if (this.phase == 1) {
            this.phase1Audio.pause()
            this.phase2Audio.pause()
        }
        if (this.phase == 2) {
            this.phase2Audio.pause()
            this.phase1Audio.pause()
        }
        if (this.phase == 3) {
            this.phase3Audio.pause()
        }
        if (this.phase == 4) {
            this.phase4Audio.pause()
        }
        if (this.phase == 5) {
            this.phase5Audio.pause()
        }
        if (this.phase == 6) {
            this.phase6Audio.pause()
        }
    }

    setUpAudio(audios) {
        this.bgAudio.setBuffer(audios[4]);
        this.bgAudio.setLoop(true);
        this.bgAudio.setVolume(0);
        this.bgAudio.pause();

        this.phase1Audio.setBuffer(audios[0]);
        this.phase1Audio.setPlaybackRate(1.1)
        this.phase1Audio.setLoop(false);
        this.phase1Audio.setVolume(0.5);
        this.phase1Audio.pause();

        this.phase2Audio.setBuffer(audios[1]);
        this.phase2Audio.setPlaybackRate(1.1)
        this.phase2Audio.setLoop(false);
        this.phase2Audio.setVolume(0.5);
        this.phase2Audio.pause();

        this.phase3Audio.setBuffer(audios[2]);
        this.phase3Audio.setPlaybackRate(1.1)
        this.phase3Audio.setLoop(false);
        this.phase3Audio.setVolume(0.5);
        this.phase3Audio.pause();

        this.phase4Audio.setBuffer(audios[3]);
        this.phase4Audio.setPlaybackRate(1.1)
        this.phase4Audio.setLoop(false);
        this.phase4Audio.setVolume(0);
        this.phase4Audio.pause();

    }


    async setUpTextures(gltf, textures) {
        let geo;
        let geoBush = null;
        let geoTree = null;


        await new Promise((resolve, reject) => {
            gltf.scene.traverse(t => {


                if (t.material && (t.material.name.includes('palm') || t.material.name.includes('tree') || t.material.name.includes('bush'))) {
                    t.visible = false;
                }

                if (t.material) {
                    if (t.material.name.includes('p4')) {
                        t.material.map = this.textures[21];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('p6')) {
                        t.material.map = this.textures[23];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('p1')) {
                        t.material.map = this.textures[19];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('p2')) {
                        t.material.map = this.textures[20];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('p5')) {
                        t.material.map = this.textures[22];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('grass')) {
                        t.material.map = this.textures[14];
                    }
                    if (t.material.name.includes('fence')) {
                        t.material.map = this.textures[7];
                        t.material.transparent = true;
                        t.material.metalness = 0.3
                    }
                    if (t.material.name.includes('camels coming2')) {
                        t.material.map = this.textures[3];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('camels coming3')) {
                        t.material.map = this.textures[4];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('camels coming1')) {
                        t.material.map = this.textures[2];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('circle 1')) {
                        t.material.map = this.textures[29];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('circle 2')) {
                        t.material.map = this.textures[30];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('circle 3')) {
                        t.material.map = this.textures[31];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('PATTERN-1')) {
                        t.material.map = this.textures[26];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('PATTERN-2')) {
                        t.material.map = this.textures[43];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('teacher wall')) {
                        t.material.map = this.textures[33];
                        t.material.metalness = 0.2
                    }
                    if (t.material.name.includes('ceiling')) {
                        t.material.map = this.textures[27];
                        t.material.metalness = 0.2
                    }
                    if (t.material.name.includes('reception wall')) {
                        t.material.map = this.textures[44];
                        t.material.metalness = 0.2
                    }
                    if (t.material.name.includes('floor')) {
                        t.material.map = this.textures[42];
                        t.material.metalness = 0.2
                    }
                    if (t.material.name.includes('visitor 1')) {
                        t.material.map = this.textures[35];
                        t.material.alphaTest = 0.3
                    }
                    if (t.material.name.includes('visitor 2')) {
                        t.material.map = this.textures[36];
                        t.material.alphaTest = 0.3
                    }
                    if (t.material.name.includes('visitor 3')) {
                        t.material.map = this.textures[37];
                        t.material.alphaTest = 0.3
                    }
                    if (t.material.name.includes('greenery palms')) {
                        t.material.map = this.textures[18];
                    }
                    if (t.material.name.includes('market 10')) {
                        t.material.map = this.textures[17];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('market 7')) {
                        t.material.map = this.textures[16];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('market 6')) {
                        t.material.map = this.textures[15];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 2')) {
                        t.material.map = this.textures[9];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 5')) {
                        t.material.map = this.textures[12];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 6')) {
                        t.material.map = this.textures[13];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 4')) {
                        t.material.map = this.textures[11];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 3')) {
                        t.material.map = this.textures[10];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('field 1')) {
                        t.material.map = this.textures[8];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('sil1 man')) {
                        t.material.map = this.textures[32];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }
                    if (t.material.name.includes('sil2 man')) {
                        t.material.map = this.textures[37];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('sil3 man')) {
                        t.material.map = this.textures[39];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;
                    }

                    if (t.name.includes('valley_greenery')) {
                        t.visible = true;
                        t.material.map = this.textures[18]
                        t.material.metalness = 0;
                        t.material.alphaTest = 0.1
                    }



                }

                if (t.material && t.name.includes('main_desert_platform')) {
                    t.material.map = textures[5];
                    t.material.metalness = 0
                    t.material.color = new THREE.Color('#ffffff')
                    t.material.toneMapped = false;
                }

                if (t.material && t.material.name.includes('walls n houses')) {
                    t.material.map = this.textures[38];
                    t.material.metalness = 0.3;
                }
                if (t.name.includes('Cube') || t.material && t.material.name.includes('door')) {
                    t.geometry.dispose();
                    t.material.dispose();
                    t.visible = false;
                }

                if (t.name.includes('palm')) {
                    t.traverse(b => {
                        if (b.geometry)
                            geo = b.geometry;
                        if (b.visible) {
                            var position = new THREE.Vector3();

                            let vec = new THREE.Vector3();
                            b.getWorldPosition(vec);

                            let vec2 = new THREE.Vector3();
                            b.getWorldScale(vec2);

                            let vec3 = new THREE.Quaternion();
                            b.getWorldQuaternion(vec3);
                            this.palms.push({
                                position: { x: vec.x, y: vec.y, z: vec.z },
                                scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                                rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                            })
                        }
                    })

                }
                if (t.name.includes('tree')) {
                    t.traverse(b => {
                        if (geoTree == null)
                            geoTree = b.geometry;

                        let vec = new THREE.Vector3();
                        b.getWorldPosition(vec);

                        let vec2 = new THREE.Vector3();
                        b.getWorldScale(vec2);

                        let vec3 = new THREE.Quaternion();
                        b.getWorldQuaternion(vec3);
                        this.trees.push({
                            position: { x: vec.x, y: vec.y, z: vec.z },
                            scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                            rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                        })
                    })

                }
                if (t.name.includes('bush')) {
                    t.traverse(b => {
                        if (geoBush == null)
                            geoBush = b.geometry;

                        let vec = new THREE.Vector3();
                        b.getWorldPosition(vec);

                        let vec2 = new THREE.Vector3();
                        b.getWorldScale(vec2);

                        let vec3 = new THREE.Quaternion();
                        b.getWorldQuaternion(vec3);
                        this.bushes.push({
                            position: { x: vec.x, y: vec.y, z: vec.z },
                            scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                            rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                        })
                    })

                }




                t.updateMatrix();
            })
            resolve(true)
        })



        await TreesScene.init(this.scene, this.camera, geo, this.bushes, geoBush, this.trees, geoTree)

        this.scene.add(gltf.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld()
    }

    setUpCharactersAnimation(gltf) {
        let gltfAnimation = gltf.animations[0];
        let tracks = gltf.animations[0].tracks;

        let trackObeid = [tracks[3], tracks[4], tracks[5], tracks[6], tracks[7], tracks[8], tracks[9], tracks[10], tracks[11]];

        let animationCamera = gltfAnimation;
        animationCamera.tracks = trackObeid;
        this.obeidAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t = this.obeidAnimationMixer.clipAction(animationCamera);
        t.clampWhenFinished = true;
        t.play();
        this.obeidAnimationMixer.update(16)



        let trackSheperd = [tracks[21], tracks[22], tracks[23], tracks[24]];
        let animationSheperd = gltfAnimation;
        animationCamera.tracks = trackSheperd;
        this.sheperdAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t2 = this.sheperdAnimationMixer.clipAction(animationSheperd);
        t2.clampWhenFinished = true;
        t2.play();
        this.sheperdAnimationMixer.update(90)

        let trackObeidLast = [tracks[12], tracks[13], tracks[14], tracks[15], tracks[16], tracks[17], tracks[18], tracks[19], tracks[20]];
        let animationObeidLast = gltfAnimation;
        animationCamera.tracks = trackObeidLast;
        this.obeidLastAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t3 = this.obeidLastAnimationMixer.clipAction(animationObeidLast);
        t3.clampWhenFinished = true;
        t3.play();
        this.obeidLastAnimationMixer.update(96)

    }
    async setUpCamera(gltf) {

        loadGLTF('../models/chapter4/cams/cam2.glb').then(gltf => {

            let cameraMixerPhase2 = new THREE.AnimationMixer(this.camera);
            this.phase2Mixer = cameraMixerPhase2;

            let t = this.phase2Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter4/cams/cam3.glb').then(gltf => {

            let cameraMixerPhase3 = new THREE.AnimationMixer(this.camera);
            this.phase3Mixer = cameraMixerPhase3;

            let t = this.phase3Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter4/cams/cam4.glb').then(gltf => {
            let cameraMixerPhase4 = new THREE.AnimationMixer(this.camera);
            this.phase4Mixer = cameraMixerPhase4;

            let t = this.phase4Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
            this.phase4Mixer.update(0);

        })
        loadGLTF('../models/chapter4/cams/cam5.glb').then(gltf => {
            let cameraMixerPhase5 = new THREE.AnimationMixer(this.camera);
            this.phase5Mixer = cameraMixerPhase5;

            let t = this.phase5Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
            this.phase5Mixer.update(0);
        })
        loadGLTF('../models/chapter4/cams/cam1.glb').then(gltf => {
            this.sceneCamera1 = new THREE.Camera()

            let cameraMixerPhase1 = new THREE.AnimationMixer(this.sceneCamera1);
            this.cameraAnimationMixers[0] = cameraMixerPhase1;
            this.phase1Mixer = cameraMixerPhase1;

            let t2 = this.phase1Mixer.clipAction(gltf.animations[0]);
            t2.clampWhenFinished = true;
            t2.setLoop(THREE.LoopOnce);
            t2.play();
            this.phase1Mixer.update(0.1);

            gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 0 })
            gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 0 })

        })

    }

    async addLine2(textures) {
        var lineGltf = await loadGLTF("../models/chapter4/line1_2.glb");

        this.lineMesh2 = lineGltf.scene.children[0];

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh2.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh2.geometry)
        lineGeo.setAttribute('position', this.lineMesh2.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        this.lineMesh2.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh2.material.clone();
        lineMat.map = textures[48]
        this.sceneLineMesh2 = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh2.material.map = textures[49]
        this.sceneLineMesh2.position.set(this.lineMesh2.position.x, this.lineMesh2.position.y, this.lineMesh2.position.z)
        this.sceneLineMesh2.rotation.set(this.lineMesh2.rotation.x, this.lineMesh2.rotation.y, this.lineMesh2.rotation.z)
        this.sceneLineMesh2.geometry.attributes.position.needsUpdate = true;

        this.sceneLineMesh2.name = 'test'
        this.lineMesh2.name = 'test2'
        this.scene.add(this.sceneLineMesh2)
        this.scene.add(this.lineMesh2)


        this.lineMixer2 = new THREE.AnimationMixer(this.lineMesh2);

        let t2 = this.lineMixer2.clipAction(lineGltf.animations[0]);
        t2.play();
        this.lineMixer2.update(50);
    }
    setUpActions(phaseNumber) {
        switch (phaseNumber) {
            case 1:
                this.lineMesh2.visible = true;
                this.sceneLineMesh2.visible = true;
                break;
            case 2:

                document.getElementById('action1').classList.remove('show')
                this.phase = 2;
                this.playPhase2 = true;
                this.playPhase1 = false;

                this.phase1Audio.pause()
                this.phase2Audio.play();
                document.getElementsByClassName('story-container')[0].classList.add('border')
                break;

            case 3:
                document.getElementById('action2').classList.remove('show')
                this.phase = 3;
                this.playPhase2 = false;
                //   this.leaving_convoy.visible = true;
                this.playPhase3 = true;
                this.phase2Audio.pause();
                this.phase3Audio.play();
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase1Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()

                })
                this.phase3Group.map(t => {
                    t.visible = true
                })

                break;
            case 4:
                document.getElementById('action3').classList.remove('show')
                this.phase = 4;
                this.playPhase4 = true;
                this.playPhase3 = false;
                this.playPhase2 = false;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                break;
            case 5:
                document.getElementById('action3').classList.remove('show')
                this.phase = 5;
                this.playPhase5 = true;
                this.playPhase4 = false;
                this.sheperdAnimation = true;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase4Audio.play();
                this.phase3Audio.pause();

                this.phase5Group.forEach(t => {
                    t.visible = true;
                })
                this.phase2Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()
                })

                break;

            case 6:
                document.getElementById('action5').classList.remove('show')
                this.phase = 6;
                this.playPhase6 = true;
                this.playPhase5 = false;
                this.obeidLastAnimation = true;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase5Audio.pause();
                this.phase6Audio.play();

                this.phase4Group.forEach(t => {
                    t.visible = false;
                })
                this.landscape_build.visible = true

                var delay = 0;

                setTimeout(() => {
                    this.base_stone.traverse(t => {
                        if (t.material) {
                            gsap.to(t.material, { opacity: 0, duration: 0.5, delay: 0.2 });
                        }
                    })
                }, 1000)

                setTimeout(() => {
                    this.landscape_build.traverse(t => {
                        if (t.material) {
                            delay += 0.1;
                            gsap.to(t.material, { opacity: 1, duration: 0.5, delay: delay })
                        }
                    })
                }, 2000)

                this.renderer.dispose()
                break;
        }
    }



    async loadCharacter(textures) {
        //  var horse = await loadGLTF("../models/chapter2/scene/horse1.glb");
        var character = await loadGLTF("../models/chapter4/scene/teacher.glb");
        var character2 = await loadGLTF("../models/chapter4/scene/visitors.glb");

        this.character1Animation = new THREE.AnimationMixer(character.scene);
        let t1 = this.character1Animation.clipAction(character.animations[0]);
        t1.setLoop(THREE.LoopRepeat);

        t1.clampWhenFinished = true;
        t1.play();
        this.character1Animation.update(30)



        this.character2Animation = new THREE.AnimationMixer(character2.scene);
        let t2 = this.character2Animation.clipAction(character2.animations[0]);
        t2.clampWhenFinished = true;
        t2.play();
        this.character2Animation.update(22)

        character.scene.autoUpdate = true;
        character.scene.matrixAutoUpdate = true;

        character2.scene.autoUpdate = true;
        character2.scene.matrixAutoUpdate = true;


        character.scene.traverse(t => {
            if (t.material) {
                if (t.name.includes('teacher')) {
                    t.material.map = this.textures[47]
                }
            }
        })
        character2.scene.traverse(t => {
            if (t.material) {
                if (t.name.includes('visitor')) {
                    t.material.map = this.textures[46]
                }
                if (t.name.includes('owner')) {
                    t.material.map = this.textures[45]
                }
            }

        })


        this.scene.add(character.scene)
        this.scene.add(character2.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }
    checkFrustum() {

        this.raycaster.setFromCamera(new THREE.Vector2(), this.camera);
        this.raycaster.far = 500
        const intersections = this.raycaster.intersectObjects([this.hotspots[0], this.hotspots[1], this.hotspots[2], this.hotspots[3]]);
        if (intersections[0]) {
            console.log(intersections[0].object.name, this.phase)
            if (this.phase == 1 && intersections[0].object.name.includes('hotspot0')) {
                //    console.log('show1')
                setActiveHotspot(0);
            }
            else if (this.phase == 2 && intersections[0].object.name.includes('hotspot1')) {
                //    console.log('show1')
                setActiveHotspot(1);
            }
            else if (this.phase == 3 && intersections[0].object.name.includes('hotspot2')) {
                //    console.log('show1')
                setActiveHotspot(2);
            }
            else if (this.phase == 4 && intersections[0].object.name.includes('hotspot3')) {
                //    console.log('show1')
                setActiveHotspot(3);
            }
            else {
                setActiveHotspot(-1);
            }
        }
        else {
            setActiveHotspot(-1);
        }

    }

    update(camera) {
        if (!this.pause) {

            if (this.playPhase2) {
                if (this.phase == 2 && this.phase2Mixer) {
                    if (this.character2Animation.time < 22.7) {
                        this.character2Animation.update(0.005)
                    }
                    else if (this.character2Animation.time >= 22.7) {
                        if (this.phase2Mixer.time > 7) {
                            // this.flag.map(t => { t.visible = true })

                            document.getElementById('action2').classList.add('show')
                            emitter.emit('animateKnob2_4', true)

                        }
                        if (this.phase2Mixer.time >= 0 && this.phase2Mixer.time <= 7) {
                            this.character1Animation.update(0.05)
                            this.phase2Mixer.update((this.speedPhase2));

                        }
                    }
                }
            }
            else if (this.playPhase3 && this.phase == 3) {
                if (this.phase3Mixer.time >= 0 && this.phase3Mixer.time <= 8.8) {
                    this.phase3Mixer.update(this.speedPhase3);
                }
                if (this.phase3Mixer.time > 8.8) {
                    this.setUpActions(4)
                }
            }

            else if (this.playPhase4 && this.phase == 4) {
                if (this.phase4Mixer.time >= 0 && this.phase4Mixer.time <= 10) {
                    this.phase4Mixer.update((this.speedPhase4));
                }
                if (this.phase4Mixer.time > 10) {
                    document.getElementById('action3').classList.add('show')
                    emitter.emit('animateKnob3_4', true)
                }
            }
            else if (this.playPhase5 && this.phase == 5) {
                if (this.phase5Mixer.time >= 0 && this.phase5Mixer.time <= 4.5) {
                    this.phase5Mixer.update((this.speedPhase5));
                }
                if (this.phase5Mixer.time < 0) {
                    this.phase5Mixer.update(0)
                }
                if (this.phase5Mixer.time > 4.5) {
                    emitter.emit('chapterDone', true)
                }
            }
        }
    }



    bind() {
        this.init = this.init.bind(this)
    }



    handleMouseDown(e) {
        this.selectedOnDown = this.getInteractiveObjectSelected();
    }

    handleMouseMove(e) {
        this.setPointer(e);

        this.delta.x = this.lastInputPosition.x - e.clientX;
        this.delta.y = this.lastInputPosition.y - e.clientY;

        this.lastInputPosition.set(e.clientX, e.clientY);
    }

    handleMouseUp(e) {
        this.setPointer(e);
        this.testForInteractiveObjects(this.selectedOnDown);
        this.selectedOnDown = null;
    }
    setPointer(e) {
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;

        this.pointer.x = (e.clientX / clientWidth) * 2 - 1;
        this.pointer.y = -(e.clientY / clientHeight) * 2 + 1;

        this.pointer.x = clamp(this.pointer.x, -1, 1);
        this.pointer.y = clamp(this.pointer.y, -1, 1);
    }
    testForInteractiveObjects(testAgainst) {
        const intersection = this.getInteractiveObjectSelected();
        if (intersection && testAgainst && intersection.object === testAgainst.object) {
            const { userData } = intersection.object;
            this.showHotspot(userData);
        }
    }

    showHotspot(userData) {
        emitter.emit('updateHotspot', userData)
        this.pause = true;
        this.pauseAudio()
    }


    handleTouchStart(e) {
        const touch = e.changedTouches[0];
        this.lastInputPosition.set(touch.clientX, touch.clientY);

        // test if object was selected on down to test against in up
        this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);
        this.selectedOnDown = this.getInteractiveObjectSelected();
        this.pointer.copy(this.pointerCopy);
    }
    handleTouchEnd(e) {
        // copy pointer and set to touch point for picking test
        this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);

        // test if object was selected
        this.testForInteractiveObjects(this.selectedOnDown);

        // reset pointer to previous state
        this.pointer.copy(this.pointerCopy);

        // if (this.selectedOnDown) {
        // 	this.selectedOnDown.object.userData.object.mouseOut(0.1);
        // }
        this.selectedOnDown = null;
    }
    handleTouchMove(e) {
        const touch = e.changedTouches[0];

        this.delta.x = this.lastInputPosition.x - touch.clientX;
        this.delta.y = clamp(this.lastInputPosition.y - touch.clientY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)

        this.lastInputPosition.set(touch.clientX, touch.clientY);

        this.resetScrollTime();

        if (this.selectedOnDown) {
            this.selectedOnDown.object.userData.object.mouseOut();
        }
    }

    MouseWheelHandler(e) {
        // cross-browser wheel delta
        var event = window.event || e; // old IE support
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));

        return false;
    }

    handleScroll(deltaY) {

        const d = deltaY// ThreeMath.clamp(deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);


        if (this.playPhase1) {
            if (this.phase == 1) {
                if (this.phase1Mixer.time >= 0.1 && this.phase1Mixer.time <= 23) {
                    document.getElementById('scrollAction').classList.add('show')
                    this.phase1Mixer.update(d * 1.5);
                    gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 0.5 })
                    gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 0.5 })

                }
                if (this.phase1Mixer.time > 23) {
                    document.getElementById('scrollAction').classList.remove('show')
                    document.getElementById('action1').classList.add('show')
                    emitter.emit('animateKnob1_4', true)

                }
                if (this.phase1Mixer.time < 0.1) {
                    this.phase1Mixer.setTime(0.1)
                    this.phase1Mixer.update(0.1)
                }
            }
        }

        this.resetScrollTime();


    }

    resetScrollTime() {
        this.lastScrollTime = Date.now();
    }
    removeObject3D(object) {
        this.disposeHierarchy(object);

        if (!(object instanceof THREE.Object3D)) return false;
        // for better memory management and performance
        if (object.geometry) {
            object.geometry.dispose();
        }
        if (object.material) {
            if (object.material instanceof Array) {
                // for better memory management and performance
                object.material.forEach(material => material.dispose());
            } else {
                // for better memory management and performance
                object.material.dispose();
            }
        }
        object.traverse(t => {
            if (t.geometry) {
                t.geometry.dispose();
            }
            if (t.material) {
                if (t.material instanceof Array) {
                    // for better memory management and performance
                    t.material.forEach(material => material.dispose());
                } else {
                    // for better memory management and performance
                    t.material.dispose();
                }
            }
        })
        if (object.parent) {
            object.parent.remove(object);
        }
        this.renderer.clear();
        this.renderer.dispose()
        // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }


    addSprite() {

        hotspots.map((hotspot, index) => {

            const mesh = new THREE.Mesh(new THREE.PlaneGeometry(50, 50))
            mesh.name = 'hotspot' + index
            mesh.visible = false
            mesh.position.set(hotspot.position.x, hotspot.position.y, hotspot.position.z)
            mesh.rotation.set(hotspot.rotation.x, hotspot.rotation.y, hotspot.rotation.z)
            mesh.scale.set(hotspot.scale.x, hotspot.scale.y, hotspot.scale.z)

            this.scene.add(mesh)
            this.hotspots.push(mesh)

        })
    }


    start() {
        setTimeout(() => {
            this.pause = false;

            this.bgAudio.play();
            this.desertWindAudio.play()
            setTimeout(() => {
                this.phase1Audio.play();
            }, 3000)
            if (this.phase == 0) {
                document.getElementById('actionIntro').classList.add('show')

            }
            this.playPhase1 = true;


            this.fpsSpeed = this.stats.getFPS()
            //   console.log('fps', this.stats.getFPS())

            if (this.fpsSpeed > 60) {
                this.speedPhase1 = 0.015;
                this.speedPhase2 = 0.005// 0.0016;
                this.speedPhase3 = 0.01;
                this.speedPhase4 = 0.015;
                this.speedPhase5 = 0.0022;
                this.speedPhase6 = 0.001;
            }
        }, 200)
    }


    destroy() {

        TreesScene.destroy()
        this.hotspots.map(hotspot => {
            this.disposeNode(hotspot)
            this.disposeHierarchy(hotspot)
        })
        this.cameraAnimationMixers && this.cameraAnimationMixers.length > 0 && this.cameraAnimationMixers[0].uncacheRoot(this.sceneCamera1);
        this.phase2Mixer && this.phase2Mixer.uncacheRoot(this.camera);
        this.phase3Mixer && this.phase3Mixer.uncacheRoot(this.camera);
        this.phase4Mixer && this.phase4Mixer.uncacheRoot(this.camera);
        this.phase5Mixer && this.phase5Mixer.uncacheRoot(this.camera);
        this.phase6Mixer && this.phase6Mixer.uncacheRoot(this.camera);


        this.phase1Audio.pause();
        this.phase2Audio.pause();
        this.phase3Audio.pause();
        this.phase4Audio.pause();
        this.phase5Audio.pause();
        this.phase6Audio.pause();
        this.bgAudio.pause();

        this.disposeNode(this.lineMesh)
        this.disposeNode(this.sceneLineMesh)


        this.scene.traverse(t => {
            this.disposeNode(t)
            this.disposeHierarchy(t)
        })



        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer = null;
        this.sheperdAnimationMixer = null;
        this.obeidLastAnimationMixer = null;

        this.count = 1;
        this.sceneLineMesh = null;
        this.lineMesh = null;

        this.scene1_obeid = null;
        this.phase = 1;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.phase2Mixer = null;
        this.phase3Mixer = null;
        this.phase4Mixer = null;
        this.phase5Mixer = null;
        this.phase6Mixer = null;

        this.mixer = null;
        this.activeAction = null;
        this.cameraPos = null;
        this.camera = null;
        this.renderer = null;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.modelReady = false
        this.animationActions = []
        this.activeAction = null;
        this.lastAction = null;
        this.animationsFolder = null;



        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.footstepAudio = new THREE.Audio(this.audioListener);

        this.getOffHorseAudio = new THREE.Audio(this.audioListener);
        this.swordAudio = new THREE.Audio(this.audioListener);
        this.pickDateUp = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.raycaster = null;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);

        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.textures.map(texture => {
            texture.dispose();
        })
        this.textures = [];


    }

    disposeNode(node) {
        if (node instanceof THREE.Mesh) {
            if (node.geometry) {
                node.geometry.dispose();
            }

            if (node.material) {

                if (node.material.map) node.material.map.dispose();
                if (node.material.lightMap) node.material.lightMap.dispose();
                if (node.material.bumpMap) node.material.bumpMap.dispose();
                if (node.material.normalMap) node.material.normalMap.dispose();
                if (node.material.specularMap) node.material.specularMap.dispose();
                if (node.material.envMap) node.material.envMap.dispose();
                if (node.material.alphaMap) node.material.alphaMap.dispose();
                if (node.material.aoMap) node.material.aoMap.dispose();
                if (node.material.displacementMap) node.material.displacementMap.dispose();
                if (node.material.emissiveMap) node.material.emissiveMap.dispose();
                if (node.material.gradientMap) node.material.gradientMap.dispose();
                if (node.material.metalnessMap) node.material.metalnessMap.dispose();
                if (node.material.roughnessMap) node.material.roughnessMap.dispose();

                node.material.dispose();   // disposes any programs associated with the material

            }
        }
    }   // disposeNode

    disposeHierarchy(node, callback) {
        for (var i = node.children.length - 1; i >= 0; i--) {
            var child = node.children[i];
            this.disposeHierarchy(child, callback);
            // callback(child);
        }
    }
}

const _instance = new DesertScene()
export default _instance