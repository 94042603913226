import * as THREE from 'three';

import gammaFrag from '../../shaders/GammaCorrection/simple.frag';
import gammaVert from '../../shaders/GammaCorrection/simple.vert';

import VignetteFrag from '../../shaders/VignettePass/simple.frag';
import VignetteVert from '../../shaders/VignettePass/simple.vert';

import VignetteFragMobile from '../../shaders/VignettePassMobile/simple.frag';
import VignetteVertMobile from '../../shaders/VignettePassMobile/simple.vert';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

import { isMobile } from "../../utils/device";


class BackgroundScene {
    constructor() {
        this.bind();
        this.scene;
        this.renderer;
        this.camera;
        this.passes = [];
        this.effectComposer;
        this.filmNoise;
        this.bokehNoise;
        this.uniform1;
        this.uniform2;
        this.uniform3;
        this.clock;
        this.postprocess = {
            LUTPass: { enabled: !1 },
            BokehPass: { enabled: !1, focus: 20, aperture: .5, maxblur: .009 },
            FilmPass: { enabled: !0, noiseIntensity: .35, scanlinesIntensity: .025, scanlinesCount: 648, grayscale: !1 },
            GammaCorrection: { enabled: !0 },
            Vignette: { enabled: !0, offset: .65, darkness: 2.6 },
            FXAA: { enabled: !0 },
            Sepia: { enabled: !1, amount: .9 },
            Bleach: { enabled: !1, opacity: .95 },
            Tilt: { horizontal: !0, vertical: !0, r: 15.7, h: 13 / 1e3, v: 13 / 1e3 },
            Colorify: { enabled: !1, color: "#f6c409" },
            Bloom: { enabled: !1, strength: .5 }
        }
    }

    init(scene, camera, renderer, effectComposer) {
        this.clock = new THREE.Clock();
        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.effectComposer = effectComposer





        const loader = new THREE.TextureLoader();
        loader.load('../../models/background.jpg', (texture) => {
            this.scene.background = texture;
        });

        this.addGammaCorrection();
        this.addVignettePass();

        Object.keys(this.passes).forEach(s => this.effectComposer.addPass(this.passes[s].pass))
    }

    bind() {
        this.init = this.init.bind(this)
    }

    addGammaCorrection() {
        let e = new ShaderPass({
            uniforms: { tDiffuse: { value: null } },

            vertexShader: gammaVert,
            fragmentShader: gammaFrag
        })
        e.enabled = true,
            this.passes.push({ name: "gammaCorrection", pass: e })

        this.uniform1 = e.uniforms;
    }

    addVignettePass() {


        if (!isMobile()) {
            this.filmNoise = new ShaderPass({
                uniforms: {
                    tDiffuse: { value: null },
                    offset: { value: 1 },
                    darkness: { value: 1.25 },
                    uTime: { value: null },
                    resolution: { value: { x: 1 / (window.innerWidth * this.renderer.getPixelRatio()), y: 1 / (window.innerHeight * this.renderer.getPixelRatio()) } }

                },

                vertexShader: VignetteVert,
                fragmentShader: VignetteFrag
            })
            this.filmNoise.enabled = this.postprocess.Vignette.enabled,
                this.filmNoise.uniforms.offset.value = this.postprocess.Vignette.offset,
                this.filmNoise.uniforms.darkness.value = this.postprocess.Vignette.darkness,
                this.passes.push({ name: "vignette", pass: this.filmNoise })

            this.uniform2 = this.filmNoise.uniforms;
        }
        else {
            let filmNoise = new ShaderPass({
                uniforms: {
                    tDiffuse: { value: null },
                    offset: { value: 1 },
                    darkness: { value: 1.25 },
                    resolution: { value: { x: 1 / (window.innerWidth * this.renderer.getPixelRatio()), y: 1 / (window.innerHeight * this.renderer.getPixelRatio()) } }

                },

                vertexShader: VignetteVertMobile,
                fragmentShader: VignetteFragMobile
            })
            filmNoise.enabled = this.postprocess.Vignette.enabled,
                filmNoise.uniforms.offset.value = this.postprocess.Vignette.offset,
                filmNoise.uniforms.darkness.value = this.postprocess.Vignette.darkness,
                this.passes.push({ name: "vignette", pass: filmNoise })

            this.uniform3 = filmNoise.uniforms;
        }



    }



    update() {
        if (this.filmNoise && this.clock) {
            this.filmNoise.uniforms.uTime.value = this.clock.getElapsedTime();
        }
    }

    dispose() {
        this.scene.background = null

        Object.keys(this.passes).forEach(s => this.effectComposer.removePass(this.passes[s].pass))
        this.passes = []
        //   this.scene = null
        this.camera = null
        this.renderer = null

        this.uniform1 = null;
        this.uniform2 = null;
        this.uniform3 = null;
        this.clock = null;
    }

}

const _instance = new BackgroundScene()
export default _instance