<template>
<metainfo>
    <template v-slot:title="{ content }">{{ content}}</template>
  </metainfo>
  <router-link :to="{ name: 'home'}" class="language_icon">
     عربي
  </router-link>
  
  <div class="content-inner">
    <div class="page-bg"></div>
        <div class="page-title">
          <h1>
            <span class="line1">Discover with us</span>
            <span class="line2">Story of the </span>
              <span class="line3">Founding Day</span>
          </h1>
        </div>

        <div class="cta" @click="playAudio">
          <router-link to="/en/foundingstory/chapter1" >Start the experiment</router-link>
        </div>

        <div class="sound-setting">
          <span>This experience is better with audio</span>
          <span><img src="../assets/mute-icon.svg" /></span>
        </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import emitter from 'tiny-emitter/instance';
  export default {
    name: 'HomeComponent',
    components: {
    },
 setup () {
    useMeta({
      title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من دارة الملك عبد العزيز',
      og:{
            title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من دارة الملك عبد العزيز',
            description:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
            image:"https://foundingday.sa/foundingstory/assets/chapter1-thumbnail.jpg"
        },
        twitter:{
          site: "@Darahfoundation",
            creator:"@Darahfoundation",
            card:'summary_large_image',
            site_name:'تجربة تفاعلية من دارة الملك عبد العزيز'
        },
      meta: [
        ...( [
          {
            vmid: "description",
            name: "description",
            content:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
          },
    
          {
            vmid: "image",
            name: "image",
            content:"https://foundingday.sa/foundingstory/assets/chapter1-thumbnail.jpg",
          },
        ]
        ),

      ],
    })
  },
    methods:{
      playAudio(){
          emitter.emit('updateAudio', true)
      }
    },
    beforeUnmount() {
    }

  }
</script>



<style lang="scss">

  .content-inner {
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;
      min-height: 90vh;

      & > div{width:100%;}


  }
  .page-bg{
    position: fixed;
    top: -5vh;
    left: calc((100vw - 115vh)/2);
    width: 115vh !important;
    height: 115vh;
    z-index: -1;
    background: url(../assets/bg-pattern.svg) no-repeat center center;
    background-size: 100% 100%;

      -webkit-animation: rotation 25s infinite linear;
      animation: rotation 25s infinite linear;

      @media all and (max-width:800px){
        top: calc((100vh - 95vw) / 2);
        left: 2.5vw;
        width: 95vw !important;
        height: 95vw !important;
      }
  }

  .page-title{
    h1{
      span{
        width:100%;
        display:block;
      }
    }
  }

  .cta a {
    text-decoration:none;
    cursor:pointer;
    display: inline-block;
    background: url(../assets/bg-btn.png) no-repeat center center;
      padding: 1.5vw 6vw 1vw 6vw;
      background-size: 100% 100%;
      font-size: var(--p3-font-size);
      color: var(--beige);

      @media all and (max-width:600px){
        padding:3.5vw 6vw 2vw 6vw;
      }
    }

.sound-setting{
  font-size: 1.2vw;
  display:inline-block;
  width: auto !important;
  position: absolute;
bottom: 0;
  span{
    display:block;
    font-size: 15px;
padding-bottom: 7px;
  }
}

</style>



