import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Chapter1 from '../pages/Chapter1.vue'
import Chapter2 from '../pages/Chapter2.vue'
import Chapter3 from '../pages/Chapter3.vue'
import Chapter4 from '../pages/Chapter4.vue'

import Home_en from '../pages/Home_en.vue'
import Chapter1_en from '../pages/Chapter1_en.vue'
import Chapter2_en from '../pages/Chapter2_en.vue'
import Chapter3_en from '../pages/Chapter3_en.vue'
import Chapter4_en from '../pages/Chapter4_en.vue'

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [
        { path: '/', redirect: { name: 'home' } },
        { path: "/foundingstory", name: 'home', component: Home },
        { path: "/foundingstory/chapter1", name: 'chapter1', component: Chapter1 },
        { path: "/foundingstory/chapter2", name: 'chapter2', component: Chapter2 },
        { path: "/foundingstory/chapter3", name: 'chapter3', component: Chapter3 },
        { path: "/foundingstory/chapter4", name: 'chapter4', component: Chapter4 },
        { path: "/en/foundingstory", name: 'home_en', component: Home_en },
        { path: "/en/foundingstory/chapter1", name: 'chapter1_en', component: Chapter1_en },
        { path: "/en/foundingstory/chapter2", name: 'chapter2_en', component: Chapter2_en },
        { path: "/en/foundingstory/chapter3", name: 'chapter3_en', component: Chapter3_en },
        { path: "/en/foundingstory/chapter4", name: 'chapter4_en', component: Chapter4_en },
    ],

})

export default router