import * as THREE from 'three';
import { palms } from './TreesPositions';
import { loadTexture } from "../../utils/asyncLoaders";
import { isMobile } from '../../utils/device';

class TreesScene {
    constructor() {
        this.bind();

        this.geo;
        this.mat;

        this.mesh = null;
        this.meshVillage2 = null;
        this.meshVillage3 = null;
        this.dummy = new THREE.Object3D();
        this.sectionWidth = 200;

        this.palms;
        this.trees, this.bushes;
        this.geoBush;
        this.matBush;
        this.meshBush;

        this.geoTree;
        this.matTree;
        this.meshTree;

    }

    addInstancedMesh() {
        this.mesh = new THREE.InstancedMesh(this.geo, this.mat, this.palms.length);
        this.scene.add(this.mesh);
        this.setInstancedMeshPositions(this.mesh, this.palms);
    }

    addInstancedMeshBush() {
        this.meshBush = new THREE.InstancedMesh(this.geoBush, this.matBush, this.bushes.length);
        this.scene.add(this.meshBush);
        this.setInstancedMeshBushPositions(this.meshBush);
    }

    addInstancedMeshTree() {
        this.meshTree = new THREE.InstancedMesh(this.geoTree, this.matTree, this.trees.length);
        this.scene.add(this.meshTree);
        this.setInstancedMeshTreesPositions(this.meshTree);
    }

    setInstancedMeshPositions(mesh, palmArray) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(palmArray[i].position.x, palmArray[i].position.y, palmArray[i].position.z);
            this.dummy.scale.set(palmArray[i].scale.x, palmArray[i].scale.y, palmArray[i].scale.z);
            this.dummy.quaternion.set(palmArray[i].rotation.x, palmArray[i].rotation.y, palmArray[i].rotation.z, palmArray[i].rotation.w);

            this.dummy.updateMatrix();
            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }

    setInstancedMeshBushPositions(mesh) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(this.bushes[i].position.x, this.bushes[i].position.y, this.bushes[i].position.z);
            this.dummy.scale.set(this.bushes[i].scale.x, this.bushes[i].scale.y, this.bushes[i].scale.z);
            this.dummy.quaternion.set(this.bushes[i].rotation.x, this.bushes[i].rotation.y, this.bushes[i].rotation.z, this.bushes[i].rotation.w);

            this.dummy.updateMatrix();


            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }
    setInstancedMeshTreesPositions(mesh) {

        for (var i = 0; i < mesh.count; i++) {

            // we add 200 units of distance (the width of the section) between each.
            this.dummy.position.set(this.trees[i].position.x, this.trees[i].position.y, this.trees[i].position.z);
            this.dummy.scale.set(this.trees[i].scale.x, this.trees[i].scale.y, this.trees[i].scale.z);
            this.dummy.quaternion.set(this.trees[i].rotation.x, this.trees[i].rotation.y, this.trees[i].rotation.z, this.trees[i].rotation.w);

            this.dummy.updateMatrix();


            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }


    async init(scene, camera, geo, bushes, geoBush, trees, geoTree) {
        this.scene = scene
        this.camera = camera

        this.palms = palms;

        let bushTexture = await loadTexture('../models/chapter2/scene/mats/bush.png')
        this.bushes = bushes;
        this.geoBush = geoBush;
        this.matBush = new THREE.MeshStandardMaterial({
            map: bushTexture,
            depthTest: true,
            depthWrite: false,
            transparent: true,
            metalness: 0.5,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })

        let treeTexture = await loadTexture('../models/chapter2/scene/mats/tree.png')
        this.trees = trees;
        this.geoTree = geoTree;
        this.matTree = new THREE.MeshStandardMaterial({
            map: treeTexture,
            depthTest: true,
            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })




        this.treeMat = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;// gltf.scene.children[0].children[0].geometry;

        this.palms = palms
        this.mat = new THREE.MeshStandardMaterial({
            map: this.treeMat,
            depthTest: true,
            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })


        this.addInstancedMesh();

        if (!isMobile()) {
            this.addInstancedMeshBush();
            this.addInstancedMeshTree();
        }


    }


    update(camera) {

    }

    bind() {
        this.init = this.init.bind(this)
    }


}

const _instance = new TreesScene()
export default _instance